import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "@/features/admin/hooks/useGetUser";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Card, CardContent } from "@/features/admin/components/ui/Card";
import { Label } from "@/features/admin/components/ui/Label";
import { Input } from "@/features/admin/components/ui/Input";
import QueryError from "@/features/admin/components/QueryError";
import Loader from "@/features/admin/components/Loader";
import BackButton from "@/features/admin/components/ui/BackButton";
import { Button } from "@/features/admin/components/ui/Button";
import InputError from "@/features/admin/components/ui/InputError";

import { useForm } from "react-hook-form";
import { useEditUser } from "@/features/admin/hooks/useEditUser";
import { errorToast, successToast } from "@/ui/toast/Toast";
import { mapDjangoValidationErrors } from "@/features/admin/lib/utils";

export default function EditUser() {
	let { userUUID } = useParams();
	const { user, isSuccess, isPending, isError, error } = useGetUser({
		userUUID,
	});

	/*
		<Breadcrumbs
			items={userBreadcrumbs({
				userUUID,
				username: user?.username || "Loading User",
			})}
		/>
	*/

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton to={`/admin/users/${userUUID}/`} />{" "}
					{isSuccess ? `Edit ${user.username}` : "Edit User"}
				</span>
			</PageTitle>
			{isSuccess && <EditUserForm user={user} />}
			{isPending && <Loader />}
			{isError && <QueryError error={error} />}
		</>
	);
}

function EditUserForm({ user }) {
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		values: {
			username: user.username,
			email: user.email,
			first_name: user.first_name,
			last_name: user.last_name,
			phone_number: user.phone_number,
		},
	});

	const { save, isPending, isError, error } = useEditUser({
		userUUID: user.uuid,
	});

	const onSubmit = async (data) => {
		// Don't resubmit
		if (isSubmitting) return;

		try {
			await save(data);
			navigate(`/admin/users/${user.uuid}/`);
			successToast("Uspješno spašene promjene");
		} catch (error) {
			mapDjangoValidationErrors({ error, setError });
		}
	};

	return (
		<Card>
			<CardContent className="pt-6">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-2 gap-6">
						<div>
							<Label htmlFor="username">Username</Label>
							<Input
								type="text"
								className="mt-1.5"
								id="username"
								{...register("username")}
							/>
							{errors.username && (
								<InputError>
									{errors.username.message}
								</InputError>
							)}
						</div>
						<div></div>
						<div>
							<Label htmlFor="email">Email</Label>
							<Input
								type="email"
								className="mt-1.5"
								id="email"
								{...register("email")}
							/>
							{errors.email && (
								<InputError>{errors.email.message}</InputError>
							)}
						</div>
						<div>
							<Label htmlFor="phone_number">Phone number</Label>
							<Input
								type="text"
								className="mt-1.5"
								id="phone_number"
								{...register("phone_number")}
							/>
							{errors.phone_number && (
								<InputError>
									{errors.phone_number.message}
								</InputError>
							)}
						</div>
						<div>
							<Label htmlFor="first_name">First Name</Label>
							<Input
								type="text"
								className="mt-1.5"
								id="first_name"
								{...register("first_name")}
							/>
							{errors.first_name && (
								<InputError>
									{errors.first_name.message}
								</InputError>
							)}
						</div>
						<div>
							<Label htmlFor="last_name">Last Name</Label>
							<Input
								type="text"
								className="mt-1.5"
								id="last_name"
								{...register("last_name")}
							/>
							{errors.last_name && (
								<InputError>
									{errors.last_name.message}
								</InputError>
							)}
						</div>
					</div>
					<div className="mt-6 flex gap-2 justify-between">
						<Button variant="outline" asChild>
							<Link to={`/admin/users/${user.uuid}/`}>
								Cancel
							</Link>
						</Button>
						<Button>{isSubmitting ? "Saving" : "Save"}</Button>
					</div>
				</form>
			</CardContent>
		</Card>
	);
}
