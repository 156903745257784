import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import Icon from "@/ui/icon/Icon";

import style from "./Select.module.css";

const Select = ({ children, ...props }) => (
	<SelectPrimitive.Root {...props}>
		<div className={style.select}>{children}</div>
	</SelectPrimitive.Root>
);

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef(function SelectTrigger(
	{ className, children, ...props },
	ref
) {
	return (
		<SelectPrimitive.Trigger
			ref={ref}
			className={`${style.selectTrigger} ${className}`}
			{...props}
		>
			{children}
			<SelectPrimitive.Icon asChild>
				<span className={style.icon}>
					<Icon icon="fa-chevron-down" />
				</span>
			</SelectPrimitive.Icon>
		</SelectPrimitive.Trigger>
	);
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef(function SelectScrollUpButton(
	{ className, ...props },
	ref
) {
	return (
		<SelectPrimitive.ScrollUpButton
			ref={ref}
			className={`${style.scrollButton} ${className}`}
			{...props}
		>
			<span className={style.icon}>
				<Icon icon="fa-chevron-up" />
			</span>
		</SelectPrimitive.ScrollUpButton>
	);
});
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef(function SelectScrollDownButton(
	{ className, ...props },
	ref
) {
	return (
		<SelectPrimitive.ScrollDownButton
			ref={ref}
			className={`${style.scrollButton} ${className}`}
			{...props}
		>
			<span className={style.icon}>
				<Icon icon="fa-chevron-down" />
			</span>
		</SelectPrimitive.ScrollDownButton>
	);
});
SelectScrollDownButton.displayName =
	SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef(function SelectContent(
	{ className, children, position = "popper", ...props },
	ref
) {
	return (
		<SelectPrimitive.Portal>
			<SelectPrimitive.Content
				ref={ref}
				className={`${style.selectContent} ${
					position === "popper" ? style.popperPosition : ""
				} ${className}`}
				position={position}
				{...props}
			>
				<SelectScrollUpButton />
				<SelectPrimitive.Viewport
					className={`${style.viewport} ${
						position === "popper" ? style.popperViewport : ""
					}`}
				>
					{children}
				</SelectPrimitive.Viewport>
				<SelectScrollDownButton />
			</SelectPrimitive.Content>
		</SelectPrimitive.Portal>
	);
});
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef(function SelectLabel(
	{ className, ...props },
	ref
) {
	return (
		<SelectPrimitive.Label
			ref={ref}
			className={`${style.selectLabel} ${className}`}
			{...props}
		/>
	);
});
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef(function SelectItem(
	{ className, children, ...props },
	ref
) {
	return (
		<SelectPrimitive.Item
			ref={ref}
			className={`${style.selectItem} ${className}`}
			{...props}
		>
			<span className={style.itemIndicatorContainer}>
				<SelectPrimitive.ItemIndicator>
					<span className={style.icon}>
						<Icon icon="fa-check" />
					</span>
				</SelectPrimitive.ItemIndicator>
			</span>
			<SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
		</SelectPrimitive.Item>
	);
});
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef(function SelectSeparator(
	{ className, ...props },
	ref
) {
	return (
		<SelectPrimitive.Separator
			ref={ref}
			className={`${style.selectSeparator} ${className}`}
			{...props}
		/>
	);
});
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
	Select,
	SelectGroup,
	SelectValue,
	SelectTrigger,
	SelectContent,
	SelectLabel,
	SelectItem,
	SelectSeparator,
	SelectScrollUpButton,
	SelectScrollDownButton,
};
