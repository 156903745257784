import { GripIcon, ChevronRightIcon, EditIcon, TrashIcon } from "lucide-react";
import ReorderableList from "../ReorderableList";
import { Button } from "../ui/Button";
import { useEffect, useMemo, useState } from "react";
import { compareArraysByID } from "../../lib/utils";
import { useGetModules } from "../../hooks/useGetModules";
import Loader from "../Loader";
import CreateModuleModal from "./CreateModuleModal";
import { useReorderModules } from "../../hooks/useReorderModules";
import { successToast } from "@/ui/toast/Toast";

export default function Modules({ course }) {
	const { modules, isSuccess, isPending, isError, error } = useGetModules({
		courseID: course.id,
	});

	const [orderedModules, setOrderedModules] = useState([]);

	useEffect(() => {
		setOrderedModules(modules);
	}, [modules]);

	const isDirty = useMemo(
		() => !compareArraysByID(modules, orderedModules),
		[modules, orderedModules]
	);

	const [isCreateModuleOpen, setIsCreateModuleOpen] = useState(false);

	const reorderModulesMutation = useReorderModules({
		courseID: course.id,
	});

	async function handleSaveClick() {
		await reorderModulesMutation.save(
			orderedModules.map((module) => module.id)
		);
		successToast("Uspjesno spašen novi poredak module");
	}

	return (
		<div>
			<h2 className="mb-1 font-semibold">Modules</h2>
			{isSuccess && (
				<ReorderableList
					items={orderedModules}
					setItems={setOrderedModules}
					itemBaseURL={`/admin/courses/${course.id}/modules/`}
				/>
			)}
			{isPending && <Loader />}

			<CreateModuleModal
				course={course}
				isOpen={isCreateModuleOpen}
				onOpenChange={setIsCreateModuleOpen}
			/>

			<div className="flex gap-2 justify-between">
				<Button
					variant="outline"
					onClick={() => setIsCreateModuleOpen(true)}
					className="mt-3"
				>
					Add Module
				</Button>
				{isDirty && (
					<Button
						variant="default"
						className="mt-3"
						onClick={handleSaveClick}
					>
						{reorderModulesMutation.isPending ? "Saving" : "Save"}
					</Button>
				)}
			</div>
		</div>
	);
}
