import { useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import InputError from "../ui/InputError";

export default function FormInput({ name, type = "text", label, ...props }) {
	const {
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	if (!label) label = capitalize(name);

	return (
		<div>
			<Label htmlFor={name}>{label}</Label>
			<Input
				type={type}
				className="mt-1.5"
				id={name}
				{...props}
				{...register(name)}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}
