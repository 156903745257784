import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
	return twMerge(clsx(inputs));
}

export function mapDjangoValidationErrors({ error, setError }) {
	if (error.response?.data?.errors) {
		const formErrors = error.response.data.errors;

		// prettier-ignore
		for (const [fieldName, fieldErrors] of Object.entries(formErrors)) {
      for (const fieldError of fieldErrors) {
        setError(fieldName, {
          type: "server",
          message: fieldError.message,
        });
      }
    }
	}
}

export function slugify(str) {
	str = str.replace(/^\s+|\s+$/g, ""); // trim leading/trailing white space
	str = str.toLowerCase(); // convert string to lowercase
	str = str
		.replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
		.replace(/\s+/g, "-") // replace spaces with hyphens
		.replace(/-+/g, "-"); // remove consecutive hyphens
	return str;
}

export function compareArraysByID(array1, array2) {
	if (array1?.length != array2?.length) return false;

	for (let i = 0; i < array1.length; i++)
		if (array1[i]?.id != array2[i]?.id) return false;

	return true;
}

export function toLocalDate(dateString) {
	const date = new Date(dateString);

	// Format to Bosnia’s timezone (CET/CEST)
	return new Intl.DateTimeFormat("bs-BA", {
		timeZone: "Europe/Sarajevo",
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	}).format(date);
}
