import React from "react";
import Button from "@/ui/button/Button";
import style from "./SkipButton.module.css";

function SkipButton({ dispatch, index, questions }) {
	const handleClick = () => {
		let actionType;
		if (index < questions.length - 1) {
			actionType = "nextQuestion";
		} else if (index === questions.length - 1) {
			actionType = "finish";
		} else {
			actionType = "restart";
		}
		dispatch({ type: actionType });
	};

	return (
		<Button className={style.skip} onClick={handleClick}>
			{index >= questions.length
				? "Pokreni ispočetka"
				: "Preskoči pitanje"}
		</Button>
	);
}

export default SkipButton;
