import { useState } from "react";
import Heart from "react-heart";
import style from "./LikeCounterButton.module.css";
import { useLikeUnit } from "@/features/courses/hooks/useLikeUnit";
import { useUnlikeUnit } from "@/features/courses/hooks/useUnlikeUnit";

function LikeCounterButton({ video }) {
	const { like } = useLikeUnit({ unit: video });
	const { unlike } = useUnlikeUnit({ unit: video });

	async function handleClick() {
		try {
			if (video?.is_liked) await unlike();
			else await like();
		} catch (e) {
			console.log("Like error", e);
		}
	}

	return (
		<div
			onClick={handleClick}
			className={`${style.likeCounter} ${
				video?.is_liked ? style.active : ""
			}`}
		>
			<Heart
				className={style.heart}
				isActive={video?.is_liked}
				inactiveColor={"var(--border-color)"}
				activeColor={"var(--primary)"}
				style={{
					width: "1.5em",
					color: video?.is_liked
						? "var(--primary)"
						: "var(--secondary)",
				}}
				onClick={() => {}}
			/>
			<span>{video?.like_count}</span>
		</div>
	);
}

export default LikeCounterButton;
