import { Avatar, AvatarFallback, AvatarImage } from "../ui/Avatar";

function userInitials(user) {
	if (user.first_name?.length > 0 && user.last_name?.length > 0)
		return user.first_name[0] + user.last_name[0];

	return user.username[0] + user.username[1];
}

export default function UserAvatar({ user }) {
	return (
		<Avatar>
			{user.picture && <AvatarImage src={user.picture} />}
			<AvatarFallback>{userInitials(user)}</AvatarFallback>
		</Avatar>
	);
}
