import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogFooter,
} from "@/features/admin/components/ui/Dialog";

import { useForm } from "react-hook-form";
import { Button } from "../ui/Button";
import { useRef } from "react";
import { mapDjangoValidationErrors } from "@/features/admin/lib/utils";
import { useNavigate } from "react-router-dom";
import { successToast } from "@/ui/toast/Toast";
import { useCreateModule } from "../../hooks/useCreateModule";
import { FormProvider } from "react-hook-form";
import FormInput from "../forms/FormInput";

export default function CreateModuleModal({ course, isOpen, onOpenChange }) {
	const formMethods = useForm({});

	const {
		handleSubmit,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = formMethods;

	const { save, isPending, isError, error } = useCreateModule({
		courseID: course.id,
	});

	const onSubmit = async (data) => {
		// Don't resubmit
		if (isSubmitting) return;

		try {
			await save(data);
			successToast("Uspješno napravljen novi module");
			onOpenChange(false);
		} catch (error) {
			mapDjangoValidationErrors({ error, setError });
		}
	};

	const submitButtonRef = useRef();

	return (
		<Dialog open={isOpen} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Create Course</DialogTitle>
					<DialogDescription>
						Enter the name of the new course
					</DialogDescription>
				</DialogHeader>

				<FormProvider {...formMethods}>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="grid gap-4"
					>
						<FormInput
							name="name"
							label="Name"
							placeholder="Module Name"
						/>

						<button
							type="submit"
							ref={submitButtonRef}
							className="hidden"
						/>
					</form>
				</FormProvider>
				<DialogFooter>
					<Button
						type="button"
						onClick={() => submitButtonRef.current.click()}
					>
						{isSubmitting ? "Creating" : "Create"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
