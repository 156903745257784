import { reorderUnits } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useReorderUnits({ courseID, moduleID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"reorder",
		],
		fn: (data) =>
			reorderUnits({ courseID, moduleID, updatedUnitsList: data }),
		invalidateKeys: [["admin", "courses", courseID, "modules", moduleID]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
