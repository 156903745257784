function QueryError({ error }) {
	if (!error) error = { message: "There was an error fetching your data" };

	return (
		<div>
			<h1>Error - {error.message}</h1>
		</div>
	);
}

export default QueryError;
