import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import BackButton from "@/features/admin/components/ui/BackButton";
import { useGetCourse } from "../../hooks/useGetCourse";
import CourseInfo from "../../components/courses/CourseInfo";
import CourseOverview from "../../components/courses/CourseOverview";
import Modules from "../../components/courses/Modules";
import CourseActions from "../../components/courses/CourseActions";

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/features/admin/components/ui/Tabs";
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

export default function ViewCourse({ tab }) {
	let { courseID } = useParams();

	const navigate = useNavigate();

	const { course, isSuccess, isPending, isError, error } = useGetCourse({
		courseID,
	});

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton to="/admin/courses/" />{" "}
					{isSuccess ? course.name : "View Course"}
				</span>
			</PageTitle>

			{isSuccess && (
				<Tabs
					defaultValue={tab}
					onValueChange={(value) => {
						navigate(`/admin/courses/${courseID}/${value}/`);
					}}
				>
					<TabsList className="mb-1">
						<TabsTrigger value="overview">Overview</TabsTrigger>
						<TabsTrigger value="details">
							Course Details
						</TabsTrigger>
						<TabsTrigger value="modules">Modules</TabsTrigger>
					</TabsList>
					<TabsContent value="overview">
						<CourseOverview course={course} />
					</TabsContent>
					<TabsContent value="details">
						<div className="grid lg:grid-cols-3 gap-4">
							<div className="lg:col-span-2">
								<CourseInfo course={course} />
							</div>
							<div>
								<CourseActions course={course} />
							</div>
						</div>
					</TabsContent>
					<TabsContent value="modules">
						<div className="max-w-3xl">
							<Modules course={course} />
						</div>
					</TabsContent>
				</Tabs>
			)}
			{isPending && <Loader />}
			{isError && <QueryError error={error} />}
		</>
	);
}
