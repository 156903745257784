import VideoUnitInfo from "./VideoUnitInfo";
import VideoUnitUpload from "./VideoUnitUpload";
import VideoUnitVideoPreview from "./VideoUnitVideoPreview";

export default function VideoUnitVideoTab({ unit }) {
	return (
		<div className="grid grid-cols-5 gap-4">
			<div className="space-y-4 col-span-3">
				<VideoUnitUpload unit={unit} />
				<VideoUnitInfo unit={unit} />
			</div>
			<div className="col-span-2">
				<VideoUnitVideoPreview unit={unit} />
			</div>
		</div>
	);
}
