import { useState } from "react";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from "@/ui/dropdownMenu/DropdownMenu";

import style from "./MoreOptions.module.css";

import { successToast } from "@/ui/toast/Toast";
import Icon from "@/ui/icon/Icon";
import { useDeleteUnitComment } from "../../hooks/useDeleteUnitComment";

function MoreOptions({ unit, comment }) {
	const { deleteComment } = useDeleteUnitComment({ unit });

	const handleCommentDelete = async function ({ comment }) {
		await deleteComment({ comment });
		successToast("Uspješno obrisan komentar");
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger className={style.moreBtn}>
				<Icon icon="fa-ellipsis" />
			</DropdownMenuTrigger>
			<DropdownMenuContent className={style.menu}>
				{/* <DropdownMenuItem className={style.item}>
					<Icon icon="fa-pen" />
					<span>Izmijeni</span>
				</DropdownMenuItem> */}
				<DropdownMenuItem
					className={style.item}
					onSelect={() => handleCommentDelete({ comment })}
				>
					<Icon icon="fa-trash" />
					<span>Obriši</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export default MoreOptions;
