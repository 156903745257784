import { useUser } from "@/features/auth/hooks/useUser";

import style from "./Settings.module.css";

import SettingsForm from "@/features/settings/components/settingsForm/SettingsForm";
import Container from "@/layouts/container/Container";
import ChangePasswordForm from "@/features/settings/components/changePasswordForm/ChangePasswordForm";
import BillingForm from "@/features/payments/components/BillingForm";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";

import SettingsTabs from "@/features/settings/components/settingsTabs/SettingsTabs";

function Settings({ currentTab }) {
	const { user, isLoading } = useUser();

	if (isLoading) return <FullPageLoader />;

	return (
		<>
			<div className={style.background}>
				<Container size="medium">
					<div className={style.header}>
						<h2>Postavke</h2>
						{/* <p>Menage your team and preformance here</p> */}
					</div>
				</Container>
			</div>
			<Container size="medium">
				<div className={style.tabsHolder}>
					<SettingsTabs currentTab={currentTab} />
				</div>
				{currentTab == "settings" && <SettingsForm user={user} />}
				{currentTab == "billing" && <BillingForm user={user} />}
				{currentTab == "change-password" && <ChangePasswordForm />}
			</Container>
		</>
	);
}

export default Settings;
