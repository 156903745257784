import { editQuestions } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useEditQuestions({ courseID, moduleID, unitID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"units",
			unitID,
			"editQuestions",
		],
		fn: (data) =>
			editQuestions({
				courseID: courseID,
				moduleID: moduleID,
				unitID: unitID,
				questions: data,
			}),
		invalidateKeys: [
			[
				"admin",
				"courses",
				courseID,
				"modules",
				moduleID,
				"units",
				unitID,
			],
		],
	});

	return { save: mutateAsync, isPending, error, isError };
}
