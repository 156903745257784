import style from "./ReviewSection.module.css";
import Modal from "@/ui/modal/Modal";

import LeaveReviewForm from "./components/leaveReviewForm/LeaveReviewForm";
import Review from "./components/reviewCard/ReviewCard";
import ReviewsOverview from "./components/reviewsOverview/ReviewsOverview";
import ReviewsFilter from "./components/reviewsFilter/ReviewsFilter";
import { useCourseReviews } from "./hooks/useCourseReviews";
import { timeAgo } from "@/utils/helpers";

// MostLikedVideos Component (Optional)
function MostLikedVideos() {
	return (
		<div className={style.mostLikedVideos}>
			<h4>Most liked</h4>
			<div className={style.likedVideos}></div>
		</div>
	);
}

const reviews = [
	{
		imgUrl: "https://randomuser.me/api/portraits/men/1.jpg",
		name: "John Doe",
		date: "Prije 3 dana",
		rating: 4,
		text: "The business course provided a comprehensive overview of modern business strategies. The instructors were knowledgeable and engaging.",
	},
	{
		imgUrl: "https://randomuser.me/api/portraits/women/2.jpg",
		name: "Jane Smith",
		date: "Prije 5 dana",
		rating: 5,
		text: "I found the course extremely beneficial. The case studies were particularly insightful and helped me understand real-world applications.",
	},
	{
		imgUrl: "https://randomuser.me/api/portraits/women/3.jpg",
		name: "Alice Johnson",
		date: "Prije 1 tjedan",
		rating: 3,
		text: "The course content was good, but I felt that some of the topics could have been covered in more depth. Overall, a solid introduction to business.",
	},
	{
		imgUrl: "https://randomuser.me/api/portraits/men/4.jpg",
		name: "Bob Brown",
		date: "Prije 2 tjedna",
		rating: 2,
		text: "While the course had some useful information, I found it to be too basic for someone with prior business knowledge. It might be better suited for beginners.",
	},
	{
		imgUrl: "https://randomuser.me/api/portraits/men/5.jpg",
		name: "Charlie Davis",
		date: "Prije 1 mjesec",
		rating: 4,
		text: "Great course with a lot of practical advice. The networking opportunities were a huge plus, and I made several valuable connections.",
	},
];

function ReviewSection({ course }) {
	const { reviews, isPending, isError, error } = useCourseReviews({ course });

	if (isPending) return <>Učitavanje...</>;
	if (isError) return <>Error: {JSON.stringify(error)}</>;

	console.log("Got reviews:", reviews);

	return (
		<Modal>
			<section className={style.reviewSection}>
				<div className={style.header}>
					<h2>Recenzije kursa</h2>

					<div className={style.filterHolder}>
						<ReviewsFilter />
					</div>
				</div>
				<div className={style.body}>
					<div className={style.main}>
						<ReviewsOverview reviews={reviews} />
						{/* <MostLikedVideos /> */}
					</div>
					<div className={style.reviewsHolder}>
						{reviews.map((r) => (
							<Review
								key={r.uuid}
								imgUrl={r?.user?.safe_picture_url}
								name={r?.user?.username}
								date={timeAgo(new Date(r?.timestamp).getTime())}
								rating={r?.stars}
								text={r?.content}
							/>
						))}
					</div>
				</div>
			</section>

			<Modal.Window name="leaveReview">
				<LeaveReviewForm />
			</Modal.Window>
		</Modal>
	);
}

export default ReviewSection;
