import { editModule } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useEditModule({ courseID, moduleID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"edit",
		],
		fn: (data) =>
			editModule({
				courseID: courseID,
				moduleID: moduleID,
				updatedModuleData: data,
			}),
		invalidateKeys: [["admin", "courses", courseID, "modules", moduleID]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
