import { Card, CardHeader, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { Link } from "react-router-dom";

export default function UnitInfo({ courseID, moduleID, unit }) {
	return (
		<Card>
			<CardHeader className="px-4 py-6 sm:px-6 flex gap-2">
				<div className="space-y-0">
					<h2 className="text-base font-semibold text-gray-900">
						Unit details
					</h2>
					<p className="max-w-2xl text-sm leading-6 text-gray-500">
						View and edit unit information
					</p>
				</div>
				<div className="ml-auto">
					<Link
						to={`/admin/courses/${courseID}/modules/${moduleID}/units/${unit.id}/edit/`}
					>
						<Button variant="outline">Edit</Button>
					</Link>
				</div>
			</CardHeader>
			<CardContent className="border-t border-gray-200 p-0">
				<dl className="divide-y divide-gray-200">
					<UnitInfoRow label="Name" value={unit.name} />
					<UnitInfoRow label="Slug" value={unit.slug} />
					<UnitInfoRow label="Description" value={unit.description} />
				</dl>
			</CardContent>
		</Card>
	);
}

function UnitInfoRow({ label, value }) {
	return (
		<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
			<dt className="text-sm font-medium text-gray-900 flex items-center">
				{label}
			</dt>
			<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
				{value}
			</dd>
		</div>
	);
}
