import { useApiGet } from "@/hooks/useApi";
import { getCourseReviews } from "../api";

export function useCourseReviews({ course }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["reviews", course.slug],
		fn: () => getCourseReviews({ course }),
	});

	const reviews = data || [];

	return { reviews, isPending, isSuccess, isError, error };
}
