import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function ThankYou() {
	const navigate = useNavigate();
	useEffect(() => {
		const timeout = setTimeout(() => {
			navigate("/");
		}, 3000);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<p className="p-8 text-center">
			Hvala vam na pretplati. Bicete redirectovani za tri sekunde.
		</p>
	);
}
