import style from "./LoginForm.module.css";
import { useLogin } from "../hooks/useLogin";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import GoogleButton from "./GoogleButton";

import InputPassword from "@/features/forms/components/InputPassword";
import FormInput from "@/features/forms/components/FormInput";
import { FormProvider, useForm } from "react-hook-form";

import Spinner from "@/features/forms/components/Spinner";

import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";

export function LoginForm() {
	const { login } = useLogin();

	const formMethods = useFormWithValidation();
	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;

	/* <div className="flex items-center">
		<Label htmlFor={field.name}>Lozinka</Label>
		<Link
			to="/forgot-password"
			className={style.forgetPassword}
		>
			Zaboravili ste lozinku?
		</Link>
	</div>
	*/

	return (
		<div className={style.holder}>
			<div className={style.subheader}>
				<h2>Prijavite se</h2>
				{/* <p>Otkrijte alate za uspjeh i ostvarite svoje ciljeve!</p> */}
			</div>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(login)} className="grid gap-4">
					<div className="grid gap-2">
						<FormInput
							name="username"
							label="Korisničko ime / Email"
							placeholder="Unesi korisničko ime"
						/>
					</div>

					<div className="grid gap-2">
						<InputPassword
							name="password"
							label="Lozinka"
							placeholder="Unesi lozinku"
							type="password"
						/>
					</div>
					<Button type="accent" className={style.btn}>
						{isSubmitting ? (
							<Spinner />
						) : (
							<>
								<span>Prijavi se</span>{" "}
								<Icon icon="fa-arrow-right" />
							</>
						)}
					</Button>
				</form>
			</FormProvider>
			<div className={style.orSeparator}>
				<span className={style.line}></span>
				<p>ili</p>
				<span className={style.line}></span>
			</div>
			<GoogleButton />
		</div>
	);
}
