import { getUsers } from "@/features/admin/api/users";
import { useApiGet } from "@/hooks/useApi";

export function useGetUsers() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin_getUsers"],
		fn: getUsers,
	});

	const { users } = data || { users: [] };

	return { users, isPending, isSuccess, isError, error };
}
