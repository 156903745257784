import {
	ClockIcon,
	DollarSign,
	MousePointerClickIcon,
	UsersIcon,
} from "lucide-react";

import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	CardDescription,
} from "@/features/admin/components/ui/Card";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/features/admin/components/ui/Table";

export default function CourseOverview({ course }) {
	return (
		<div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<StatsCard
				title="Page Views"
				value="713"
				Icon={MousePointerClickIcon}
			/>
			<StatsCard title="Audience Size" value="13" Icon={UsersIcon} />
			<StatsCard
				title="Total Minutes Watched"
				value="352"
				Icon={ClockIcon}
			/>
			<StatsCard
				title="Average Minutes Watched"
				value="12"
				Icon={ClockIcon}
			/>
			<div className="md:col-span-2">
				<WatchTimeByVideo />
			</div>
			<div className="md:col-span-2">
				<WatchTimeByUser />
			</div>
		</div>
	);
}

function StatsCard({ title, value, subtitle, Icon }) {
	return (
		<Card>
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="text-sm font-medium">{title}</CardTitle>
				<Icon className="h-5 w-5 text-muted-foreground" />
			</CardHeader>
			<CardContent>
				<div className="text-2xl font-bold">{value}</div>
				{subtitle && (
					<p className="text-xs text-muted-foreground">{subtitle}</p>
				)}
			</CardContent>
		</Card>
	);
}

function WatchTimeByVideo() {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead>Video Name</TableHead>
					<TableHead className="text-right">
						Minutes Watched
					</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				<TableRow>
					<TableCell>
						<span className="font-medium">Introduction</span>
					</TableCell>
					<TableCell className="text-right">32</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<span className="font-medium">Example Video</span>
					</TableCell>
					<TableCell className="text-right">16</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<span className="font-medium">How to Learn</span>
					</TableCell>
					<TableCell className="text-right">12</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}

function WatchTimeByUser() {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead>Full Name</TableHead>
					<TableHead className="text-right">
						Minutes Watched
					</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				<TableRow>
					<TableCell>
						<span className="font-medium">Admir Zatega</span>
					</TableCell>
					<TableCell className="text-right">32</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<span className="font-medium">Furkan Nurovic</span>
					</TableCell>
					<TableCell className="text-right">16</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<span className="font-medium">Ekrem Nurovic</span>
					</TableCell>
					<TableCell className="text-right">5</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}
