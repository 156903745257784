import { useApiSend } from "@/hooks/useApi";
import { deleteComment, postUnitComment } from "../api";

export function useDeleteUnitComment({ unit }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		invalidateKeys: [["comments"]],
		fn: ({ comment }) => deleteComment({ comment }),
	});

	return { deleteComment: mutateAsync, isPending, error, isError };
}
