import { useApiSend } from "@/hooks/useApi";
import { likeUnit } from "../api";
import { useQueryClient } from "@tanstack/react-query";

export function useLikeUnit({ unit }) {
	const queryClient = useQueryClient();

	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"courses",
			unit.module.course.slug,
			"modules",
			unit.module.slug,
			"units",
			unit.slug,
			"like",
		],
		invalidateKeys: [["courses", unit.module.course.slug, "units", "list"]],
		fn: async () => {
			const unitDataQueryKey = [
				"courses",
				unit.module.course.slug,
				"modules",
				unit.module.slug,
				"units",
				unit.slug,
			];

			await queryClient.cancelQueries({
				queryKey: unitDataQueryKey,
			});

			// Snapshot the previous value
			const previousUnitData = queryClient.getQueryData(unitDataQueryKey);

			// Optimistically update to the new value
			queryClient.setQueryData(unitDataQueryKey, (old) => {
				return {
					...old,
					video: {
						...old?.video,
						is_liked: true,
						like_count: old?.video?.is_liked
							? old?.video?.like_count
							: old?.video?.like_count + 1,
					},
				};
			});

			try {
				await likeUnit({ unit });
			} catch (e) {
				// Revert if it fails
				queryClient.setQueryData(unitDataQueryKey, previousUnitData);
				throw e;
			}
		},
	});

	return { like: mutateAsync, isPending, error, isError };
}
