import { useState, useEffect } from "react";
import style from "./Options.module.css";

import { shuffleArray } from "@/utils/helpers";

function Options({ question, dispatch, answer, hasAnswered }) {
	const [shuffledAnswers, setShuffledAnswers] = useState([]);

	useEffect(() => {
		setShuffledAnswers(shuffleArray([...question.answers]));
	}, [question]);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (hasAnswered) return;

			const key = event.key.toLowerCase();
			const index = key.charCodeAt(0) - 97;
			if (index >= 0 && index < shuffledAnswers.length) {
				dispatch({
					type: "answerSelected",
					payload: shuffledAnswers[index],
				});
			}
		};

		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [shuffledAnswers, dispatch, hasAnswered]);

	return (
		<div className={style.options}>
			{shuffledAnswers.map((ans, index) => (
				<button
					onClick={() =>
						dispatch({ type: "answerSelected", payload: ans })
					}
					disabled={hasAnswered}
					key={ans.uuid}
					className={`${style.btn}  
          ${answer && answer.uuid === ans.uuid ? style.answer : ""} ${
						hasAnswered
							? answer.isCorrect
								? style.correct
								: style.wrong
							: ""
					}`}
				>
					<span className={style.letter}>
						{String.fromCharCode(97 + index)}
					</span>
					{ans.answer}
				</button>
			))}
		</div>
	);
}

export default Options;
