import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Link, NavLink, useParams } from "react-router-dom";
import { useGetUser } from "@/features/admin/hooks/useGetUser";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/features/admin/components/ui/Card";
import {
	Breadcrumbs,
	userBreadcrumbs,
} from "@/features/admin/components/Breadcrumbs";
import BackButton from "@/features/admin/components/ui/BackButton";
import UserRoleBadge from "@/features/admin/components/users/UserRoleBadge";
import UserAvatar from "@/features/admin/components/users/UserAvatar";
import { Button } from "@/features/admin/components/ui/Button";

function ViewUser() {
	let { userUUID } = useParams();
	const { user, isSuccess, isPending, isError, error } = useGetUser({
		userUUID,
	});

	/*
		<Breadcrumbs
			items={userBreadcrumbs({
				userUUID,
				username: user?.username || "Loading User",
			})}
		/>
	*/

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton to="/admin/users/" />{" "}
					{isSuccess ? user.username : "View User"}
				</span>
			</PageTitle>
			{isSuccess && (
				<div className="grid lg:grid-cols-3 gap-4 lg:gap-6">
					<div className="order-1 lg:order-2">
						<UserActions user={user} />
					</div>
					<div className="lg:col-span-2 order-2 lg:order-1">
						<UserInfo user={user} />
					</div>
				</div>
			)}
			{isPending && <Loader />}
			{isError && <QueryError error={error} />}
		</>
	);
}

export default ViewUser;

function UserInfo({ user }) {
	return (
		<Card>
			<CardHeader className="px-4 py-6 sm:px-6 flex gap-2">
				<div className="space-y-1">
					<h2 className="text-base font-semibold text-gray-900">
						User details
					</h2>
					<p className="max-w-2xl text-sm mt-0 leading-6 text-gray-500">
						Personal information and ID
					</p>
				</div>
				<div className="ml-auto">
					<Link to={`/admin/users/${user.uuid}/edit/`}>
						<Button variant="outline">Edit</Button>
					</Link>
				</div>
			</CardHeader>
			<CardContent className="border-t border-gray-200 p-0">
				<dl className="divide-y divide-gray-200">
					<UserInfoRow label="UUID" value={user.uuid} />
					<UserInfoRow label="Username" value={user.username} />
					<UserInfoRow
						label="Role"
						value={<UserRoleBadge user={user} />}
					/>
					<UserInfoRow
						label="Picture"
						value={<UserAvatar user={user} />}
					/>
					<UserInfoRow
						label="Full name"
						value={`${user.first_name} ${user.last_name}`}
					/>
					<UserInfoRow label="Email" value={user.email} />
					<UserInfoRow
						label="Phone Number"
						value={user.phone_number}
					/>
				</dl>
			</CardContent>
		</Card>
	);
}

function UserInfoRow({ label, value }) {
	return (
		<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
			<dt className="text-sm font-medium text-gray-900 flex items-center">
				{label}
			</dt>
			<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
				{value}
			</dd>
		</div>
	);
}

function UserActions({ user }) {
	return (
		<Card>
			<CardContent className="p-6">
				<div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-2">
					<Button variant="outline">Reset Password</Button>
					<Button>Change role</Button>
				</div>
			</CardContent>
		</Card>
	);
}
