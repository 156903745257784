import React from "react";
import style from "./LikeCommentButton.module.css";

import Icon from "@/ui/icon/Icon";
import { useLikeComment } from "../../hooks/useLikeComment";
import { useUnlikeComment } from "../../hooks/useUnlikeComment";

function LikeCommentButton({ comment }) {
	const { like } = useLikeComment({ comment });
	const { unlike } = useUnlikeComment({ comment });

	return (
		<button
			onClick={async () => {
				if (comment?.is_liked) await unlike();
				else await like();
			}}
			className={`${style.likeBtn} ${
				comment?.is_liked ? style.active : ""
			}`}
		>
			<div className={style.icoHolder}>
				<Icon icon="fa-thumbs-up"></Icon>
			</div>
			<span>{comment?.like_count}</span>
		</button>
	);
}

export default LikeCommentButton;
