import Icon from "@/ui/icon/Icon";

const categoriesWithIcons = [
	{
		slug: "kreativa",
		icon: "fa-palette",
	},
	{
		slug: "tehnologija",
		icon: "fa-desktop",
	},
	{
		slug: "biznis",
		icon: "fa-briefcase-blank",
	},
];

function CourseCategory({ course, ...props }) {
	const courseCategorySlugs = course.categories.map(
		(category) => category.slug
	);

	const iconCategories = categoriesWithIcons.filter((category) =>
		courseCategorySlugs.includes(category.slug)
	);

	if (iconCategories.length == 0) return <div {...props} />;

	return (
		<div {...props}>
			<Icon icon={iconCategories[0].icon} />
			<span>
				{
					course.categories.filter(
						(category) => category.slug == iconCategories[0].slug
					)[0].name
				}
			</span>
		</div>
	);
}

export default CourseCategory;
