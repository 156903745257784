import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { useGetCourses } from "@/features/admin/hooks/useGetCourses";
import CoursesTable from "@/features/admin/components/courses/CoursesTable";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";

export default function Courses() {
	const { courses, isSuccess, isPending, isError, error } = useGetCourses();

	return (
		<>
			<PageTitle>Courses</PageTitle>
			{isSuccess && <CoursesTable courses={courses} />}
			{isPending && <Loader />}
			{isError && <QueryError error={error} />}
		</>
	);
}
