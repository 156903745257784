import { useFormContext } from "react-hook-form";
import Label from "./Label";
import InputError from "./InputError";

import style from "./FormInput.module.css";

export default function FormInput({
	name,
	required,
	notAllowed,
	label,
	...props
}) {
	const {
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	return (
		<div className={style.holder}>
			{label && (
				<Label required={required} htmlFor={name}>
					{label}
				</Label>
			)}

			<input
				className={style.input}
				type="text"
				id={name}
				{...props}
				{...register(name)}
				required={required}
				disabled={isSubmitting || notAllowed}
			/>

			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}
