import LikeCommentButton from "../likeCommentButton/LikeCommentButton";
import ReplyButton from "../replyButton/ReplyButton";
import style from "./Replies.module.css";

import ReplyForm from "../replyForm/ReplyForm";

import { timeAgo } from "@/utils/helpers";
import { useState } from "react";
import MoreOptions from "../moreOptions/MoreOptions";

function Replies({ user, unit, comment }) {
	const [replyingTo, setReplyingTo] = useState(null);

	return (
		<ul>
			{comment.replies.map((reply) => (
				<li key={reply.uuid} className={style.reply}>
					<div className={style.header}>
						<div className={style.userInfo}>
							<img src={reply.user?.safe_picture_url} />
							<span className={style.username}>
								{reply.user?.username}
							</span>
							<span className={style.sep}></span>
							<span className={style.timestamp}>
								{timeAgo(reply.timestamp)}
							</span>
						</div>
						<div className={style.more}>
							{(reply?.user?.username == user?.username ||
								user?.role == "admin") && (
								<MoreOptions unit={unit} comment={reply} />
							)}
						</div>
					</div>
					<div className={style.body}>
						<span className={style.content}>{reply.content}</span>
					</div>

					<div className={style.footer}>
						<ReplyButton
							setIsReplying={() =>
								setReplyingTo(
									replyingTo === reply.uuid
										? null
										: reply.uuid
								)
							}
							isReplying={replyingTo === reply.uuid}
							commentId={reply.uuid}
							replyingTo={replyingTo}
						/>
						<LikeCommentButton comment={reply} />
					</div>
					{replyingTo === reply.uuid && (
						<ReplyForm
							comment={comment}
							unit={unit}
							isReplying={true}
							setIsReplying={() => setReplyingTo(null)}
						/>
					)}
				</li>
			))}
		</ul>
	);
}

export default Replies;
