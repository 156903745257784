import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URI } from "@/utils/constants";

async function generateState() {
	const randomData = new Uint8Array(1024); // 1024 bytes of random data
	window.crypto.getRandomValues(randomData); // Fill with secure random values

	const hashBuffer = await window.crypto.subtle.digest("SHA-256", randomData); // Hash the data
	const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
	const hashHex = hashArray
		.map((byte) => byte.toString(16).padStart(2, "0"))
		.join(""); // Convert to hex

	return hashHex;
}

export function useLoginWithGoogle() {
	const loginWithGoogle = async function () {
		let url = "https://accounts.google.com/o/oauth2/v2/auth";

		const state = await generateState();
		localStorage.setItem("google-state", state);

		url += `?response_type=code`;
		url += `&state=${encodeURIComponent(state)}`;
		url += `&scope=${encodeURIComponent("openid email profile")}`;
		url += `&client_id=${encodeURIComponent(GOOGLE_CLIENT_ID)}`;
		url += `&redirect_uri=${encodeURIComponent(GOOGLE_REDIRECT_URI)}`;
		window.location.href = url;
	};

	return {
		loginWithGoogle,
	};
}
