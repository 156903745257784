import { useForm } from "react-hook-form";
import { mapDjangoValidationErrors } from "../utils";

export function useFormWithValidation(props) {
	const formMethods = useForm({ ...props });
	const {
		handleSubmit,
		setError,
		formState: { isSubmitting },
	} = formMethods;

	const customHandleSubmit = (onSubmit) => {
		return handleSubmit(async (data) => {
			try {
				await onSubmit(data);
			} catch (error) {
				mapDjangoValidationErrors({ error, setError });
			}
		});
	};

	return { ...formMethods, handleSubmit: customHandleSubmit };
}
