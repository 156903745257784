import { reorderModules } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useReorderModules({ courseID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["admin", "courses", courseID, "modules", "reorder"],
		fn: (data) => reorderModules({ courseID, updatedModulesList: data }),
		invalidateKeys: [["admin", "courses", courseID, "modules"]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
