import { useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import { Textarea } from "../ui/Textarea";
import InputError from "../ui/InputError";

export default function FormTextArea({ name, label, ...props }) {
	const {
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	if (!label) label = capitalize(name);

	return (
		<div className="flex flex-col">
			<Label htmlFor={name}>{label}</Label>
			<Textarea
				className="mt-1.5 flex-grow"
				id={name}
				{...props}
				{...register(name)}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}
