import { useApiGet } from "@/hooks/useApi";
import { getCategories } from "@/features/admin/api/courses";

export function useGetCategories() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin", "categories"],
		fn: getCategories,
	});

	const { categories } = data || { categories: [] };

	return { categories, isPending, isSuccess, isError, error };
}
