import { AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME } from "@/utils/constants";
import { register } from "../api";
import { useApiSend } from "@/hooks/useApi";

export function useRegister() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: async function (data) {
			const response = await register({ data });
			localStorage.setItem(
				AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME,
				response.token
			);
			return response;
		},
		invalidateKeys: [["whoami"]],
		resetKeys: [["whoami"]],
	});

	return { register: mutateAsync, isPending, error, isError };
}
