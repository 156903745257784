import React from "react";

import style from "./AnswerRespond.module.css";
import NextButton from "../nextButton/NextButton";

import Container from "@/layouts/container/Container";
import Icon from "@/ui/icon/Icon";

function AnswerRespond({
	dispatch,
	index,
	questions,
	quiz,
	answer,
	isCorrectAnswer,
	correctAnswer,
}) {
	const correctMessage = ["Tačan odgovor!", "Bravo!", "Svaka čast!"];

	return (
		<div
			className={`${style.answerRespond} ${
				isCorrectAnswer ? style.correct : style.wrong
			}`}
		>
			<Container size="medium">
				<div className={style.status}>
					<div className={style.icoHolder}>
						{isCorrectAnswer ? (
							<Icon icon="fa-badge-check" />
						) : (
							<Icon icon="fa-times" />
						)}
					</div>
					<div className={style.messageHolder}>
						<h4>
							{isCorrectAnswer
								? correctMessage[
										Math.floor(
											Math.random() *
												correctMessage.length
										)
								  ]
								: Math.random() == 0
								? "Vas odgovor je čista nula."
								: "Niste tačno odgovorili."}
						</h4>
						<p>
							{isCorrectAnswer ? (
								"Odgovorili ste tačno."
							) : (
								<>
									<span>Tačan odgovor je:</span>{" "}
									{correctAnswer}
								</>
							)}
						</p>
					</div>
				</div>
				<div>
					<NextButton
						index={index}
						numQuestions={questions.length}
						dispatch={dispatch}
						quiz={quiz}
						answer={answer}
					/>
				</div>
			</Container>
		</div>
	);
}

export default AnswerRespond;
