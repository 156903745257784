import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";
import FormInput from "@/features/forms/components/FormInput";
import Spinner from "@/features/forms/components/Spinner";
import { useEditSettings } from "@/features/settings/hooks/useEditSettings";

import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";

import style from "./RegisterSettingsStep.module.css";
import InputPhone from "@/features/forms/components/InputPhone";

function RegisterSettingsStep({ user, nextStep }) {
	console.log(user);
	const formMethods = useFormWithValidation({
		defaultValues: {
			username: user.username,
			first_name: user.firstName,
			last_name: user.lastName,
			phone_number: user.phoneNumber,
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;

	const { save } = useEditSettings();

	const onSubmit = async function (data) {
		await save(data);
		nextStep();
	};

	return (
		<div className={style.content}>
			<div className={style.header}>
				<Icon icon="fa-user" />
				<h2>Unos personalnih informacija</h2>
			</div>
			<FormProvider {...formMethods}>
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<FormInput
						name="username"
						autoComplete="username"
						label="Korisničko ime"
						placeholder="korisnik123"
						required
					/>
					<FormInput
						name="first_name"
						label="Ime"
						placeholder="Unesi ime"
						required
					/>
					<FormInput
						name="last_name"
						label="Prezime"
						placeholder="Unesi prezime"
						required
					/>
					<InputPhone
						name="phone_number"
						label="Broj telefona"
						placeholder="Unesi broj telefona"
						required
					/>
					<div className={style.buttonHolder}>
						<Button disabled={isSubmitting} type="accent">
							{isSubmitting ? <Spinner /> : "Nastavi"}
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
}

export default RegisterSettingsStep;
