import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { errorToast } from "@/ui/toast/Toast";
import { redirect, useNavigate } from "react-router-dom";

function handleRequestError(error, navigate) {
	const response = error.response;

	let errorMessage = "Nepoznata greška"; // Default error message

	// console.log(error);
	if (response) {
		// console.log(response);
		switch (response.status) {
			case 400:
				errorMessage = "Imate greške u formi.";
				break;
			case 401:
				errorMessage = "Molimo vas da se prijavite.";
				break;
			case 404:
				errorMessage = "Resurs nije pronađen.";
				break;
			case 422:
				errorMessage = "Forma nije mogla biti procesirana";
				break;
			case 500:
				errorMessage = "Interna greška servera.";
				break;
			default:
				errorMessage = response.statusText || errorMessage;
		}
	} else if (error.message) {
		errorMessage = error.message;
	}

	// No access - either not logged in as admin or not subscribed and need to be
	if (response && response?.status == 403) {
		if (response?.data?.error == "not-subscribed") {
			errorToast("Pretplatite se da biste pristupili ovom resursu");
			navigate("/plans", { replace: true });
		} else {
			errorToast(
				"Zabranjen pristup. Možda niste prijavljeni u pravi račun?"
			);
			navigate("/", { replace: true });
		}
		return;
	}

	// Don't toast for incorrect forms
	if (response?.status != 400) errorToast(errorMessage);

	// Redirect if requires login
	if (response && response?.status == 401) {
		navigate("/login", { replace: true });
	}
}

function shouldRetry(failureCount, error, navigate) {
	// console.log(error, failureCount);

	if (error?.response) {
		// Handle response code
		handleRequestError(error, navigate);

		// Don't retry if we reached the server and got a response
		return false;
	}

	// Retry logic for network errors etc.
	// Max 3 requests (2 retries)
	return failureCount < 2;
}

export const useApiGet = ({ key, fn, options }) => {
	const navigate = useNavigate();

	return useQuery({
		queryKey: key,
		queryFn: fn,
		retry: (failureCount, error) =>
			shouldRetry(failureCount, error, navigate),
		refetchOnMount: false,
		...options,
	});
};

export const useApiSend = ({
	mutationKeys,
	fn,
	onSuccess,
	onError,
	invalidateKeys,
	resetKeys,
	options,
}) => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	return useMutation({
		mutationKey: mutationKeys,
		mutationFn: fn,
		onSuccess: (data) => {
			if (onSuccess) onSuccess(data);

			if (invalidateKeys) {
				invalidateKeys.forEach((key) => {
					queryClient.invalidateQueries({ queryKey: key });
				});
			}

			// sometimes it's better to remove them entirely and show the loader
			if (resetKeys) {
				console.log("Reseting");
				resetKeys.forEach((key) => {
					queryClient.resetQueries({ queryKey: key });
				});
			}
		},
		onError: (error, variables, context) => {
			if (onError) onError(error, variables, context);
		},
		retry: (failureCount, error) =>
			shouldRetry(failureCount, error, navigate),
		...options,
	});
};
