import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const columns = [
	columnHelper.accessor("username", {
		header: "Username",
	}),
	columnHelper.accessor((row) => row.first_name + " " + row.last_name, {
		id: "full_name",
		header: "Full Name",
	}),
	columnHelper.accessor("email", {
		header: "Email",
	}),
	columnHelper.accessor(
		(row) => {
			if (row.is_superuser) return "Superadmin";
			if (row.role == "admin") return "Admin";
			if (row.role == "user") return "User";
			return "Unknown";
		},
		{
			id: "role",
			header: "Role",
			cell: (info) => info.getValue(),
		}
	),
];
