import { Bell, CircleUser, Menu, Search, ArrowLeft } from "lucide-react";

import { Link, NavLink, Outlet } from "react-router-dom";
import { Badge } from "@/features/admin/components/ui/Badge";
import { Button } from "@/features/admin/components/ui/Button";
import { APP_NAME } from "@/utils/constants";
import { useState } from "react";

import appIcon from "@/assets/images/logo.svg";

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/features/admin/components/ui/Card";

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/features/admin/components/ui/DropdownMenu";

import { Input } from "@/features/admin/components/ui/Input";
import {
	Sheet,
	SheetContent,
	SheetTrigger,
} from "@/features/admin/components/ui/Sheet";

import SidebarLinks from "./components/SidebarLinks";
import SidebarLink from "./components/SidebarLink";

export function AdminLayout() {
	const [sheetOpen, setSheetOpen] = useState(false);

	return (
		<div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
			<div className="hidden border-r bg-muted/60 md:block">
				<div className="flex h-full max-h-screen flex-col gap-2">
					<div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
						<Link
							to="/admin/"
							className="flex items-center gap-2 font-semibold"
						>
							<img src={appIcon} className="h-16 py-3" />
						</Link>
						<Button
							variant="outline"
							size="icon"
							className="ml-auto h-8 w-8"
						>
							<Bell className="h-4 w-4" />
							<span className="sr-only">
								Toggle notifications
							</span>
						</Button>
					</div>
					<div className="flex-1">
						<nav className="grid items-start gap-1 px-2 py-2 lg:px-4">
							<SidebarLinks variant="desktop" />
						</nav>
					</div>
					<div className="mt-auto py-3 px-2 lg:px-4">
						<SidebarLink
							url="/"
							name="Back to App"
							variant="desktop"
							className={"border border-gray-300 shadow-sm"}
							Icon={ArrowLeft}
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-col max-h-screen">
				<header className="flex h-14 flex-shrink-0 items-center gap-4 border-b bg-muted/60 px-4 lg:h-[60px] lg:px-6">
					<Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
						<SheetTrigger asChild>
							<Button
								variant="outline"
								size="icon"
								className="shrink-0 md:hidden"
							>
								<Menu className="h-5 w-5" />
								<span className="sr-only">
									Toggle navigation menu
								</span>
							</Button>
						</SheetTrigger>
						<SheetContent side="left" className="flex flex-col">
							<nav className="grid gap-2 text-lg font-medium">
								<Link
									to="/admin/"
									className="flex items-center gap-2 font-semibold"
								>
									<img src={appIcon} className="h-16 py-3" />
								</Link>

								<SidebarLinks
									closeSidebar={() => setSheetOpen(false)}
									variant="mobile"
								/>
							</nav>
							<div className="mt-auto">
								<SidebarLink
									url="/"
									name="Back to App"
									variant="desktop"
									className={
										"border border-gray-300 shadow-sm"
									}
									Icon={ArrowLeft}
								/>
							</div>
						</SheetContent>
					</Sheet>
					<div className="w-full max-w-2xl mx-auto">
						{/*<form className="w-full">
							<div className="relative">
								<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
								<Input
									type="search"
									placeholder="Search products..."
									className="appearance-none bg-background pl-8 shadow-none w-full"
								/>
							</div>
						</form>
						*/}
					</div>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="secondary"
								size="icon"
								className="rounded-full"
							>
								<CircleUser className="h-5 w-5" />
								<span className="sr-only">
									Toggle user menu
								</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuLabel>My Account</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuItem>Settings</DropdownMenuItem>
							<DropdownMenuItem>Support</DropdownMenuItem>
							<DropdownMenuSeparator />
							<DropdownMenuItem>Logout</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</header>
				<main className="w-full overflow-auto">
					<div className="flex flex-1 flex-col gap-4 p-4 lg:p-6 w-full max-w-7xl mx-auto">
						<Outlet />
					</div>
				</main>
			</div>
		</div>
	);
}

export default AdminLayout;
