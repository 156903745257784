import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogFooter,
} from "@/features/admin/components/ui/Dialog";

import { useForm } from "react-hook-form";
import { Label } from "../ui/Label";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { useCreateCourse } from "../../hooks/useCreateCourse";
import { useRef } from "react";
import InputError from "../ui/InputError";
import { mapDjangoValidationErrors } from "@/features/admin/lib/utils";
import { useNavigate } from "react-router-dom";
import { successToast } from "@/ui/toast/Toast";

export default function CreateCourseModal({ isOpen, onOpenChange }) {
	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({});

	const { save, isPending, isError, error } = useCreateCourse();
	const navigate = useNavigate();

	const onSubmit = async (data) => {
		// Don't resubmit
		if (isSubmitting) return;

		try {
			const responseData = await save(data);
			navigate(`/admin/courses/${responseData.course.id}/`);
			successToast("Uspješno napravljen novi kurs");
		} catch (error) {
			mapDjangoValidationErrors({ error, setError });
		}
	};

	const submitButtonRef = useRef();

	return (
		<Dialog open={isOpen} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Create Course</DialogTitle>
					<DialogDescription>
						Enter the name of the new course
					</DialogDescription>
				</DialogHeader>
				<form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
					<div className="space-y-1">
						<Input
							id="name"
							placeholder="Course Name"
							className="col-span-3"
							{...register("name")}
						/>
						{errors.name && (
							<InputError>{errors.name.message}</InputError>
						)}
					</div>

					<button
						type="submit"
						ref={submitButtonRef}
						className="hidden"
					/>
				</form>
				<DialogFooter>
					<Button
						type="button"
						onClick={() => submitButtonRef.current.click()}
					>
						{isSubmitting ? "Creating" : "Create"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
