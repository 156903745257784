import { useApiSend } from "@/hooks/useApi";
import { postUnitComment } from "../api";

export function usePostUnitComment({ unit }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		invalidateKeys: [["comments"]],
		fn: ({ content, reply_to }) =>
			postUnitComment({ unit, content, reply_to }),
	});

	return { save: mutateAsync, isPending, error, isError };
}
