import { getModule } from "../api/courses";
import { useApiGet } from "@/hooks/useApi";

export function useGetModule({ courseID, moduleID }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin", "courses", courseID, "modules", moduleID],
		fn: () => getModule({ courseID, moduleID }),
	});

	const { module } = data || { module: {} };

	return { module, isPending, isSuccess, isError, error };
}
