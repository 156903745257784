import React, { useEffect, useCallback } from "react";
import style from "./CheckButton.module.css";
import Button from "@/ui/button/Button";

function CheckButton({ dispatch, answer, index, questions }) {
	const handleKeyDown = useCallback(
		async (event) => {
			if (event.key === "Enter" && answer) {
				dispatch({ type: "checkAnswer", payload: answer });
			}
		},
		[answer, dispatch]
	);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const handleClick = useCallback(() => {
		dispatch({ type: "checkAnswer", payload: answer });
	}, [answer, dispatch]);

	// ovde sad treba da  kaze ako je pitanje posldje da ima nastavi dalje dugme, a ako nema dalje da ide go back to home

	return index == questions.length ? (
		<Button className={style.check} to="/">
			Nastavi dalje
		</Button>
	) : (
		<Button
			className={style.check}
			onClick={handleClick}
			disabled={!answer}
		>
			Provjeri
		</Button>
	);
}

export default CheckButton;
