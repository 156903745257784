import { request } from "@/services/network";

export async function getUnitComments({ unit }) {
	return request({
		url: `/courses/${unit.module.course.slug}/${unit.module.slug}/${unit.slug}/comments/`,
		method: "GET",
	}).then((data) => {
		return {
			comments: data.map((comment) => {
				return {
					...comment,
					replies: comment.replies.map((reply) => {
						return {
							...reply,
							timestamp: Date.parse(reply.timestamp),
						};
					}),
					timestamp: Date.parse(comment.timestamp),
				};
			}),
		};
	});
}

export async function postUnitComment({ unit, content, reply_to }) {
	return request({
		url: `/courses/${unit.module.course.slug}/${unit.module.slug}/${unit.slug}/comments/`,
		method: "POST",
		data: {
			content,
			reply_to,
		},
	});
}

export async function likeComment({ comment }) {
	return request({
		url: `/comments/${comment.uuid}/like/`,
		method: "POST",
	});
}

export async function unlikeComment({ comment }) {
	return request({
		url: `/comments/${comment.uuid}/unlike/`,
		method: "POST",
	});
}

export async function deleteComment({ comment }) {
	return request({
		url: `/comments/${comment.uuid}/`,
		method: "DELETE",
	});
}
