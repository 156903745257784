import style from "./ReviewsOverview.module.css";

import ReviewsStatistic from "../reviewsStatistic/ReviewsStatistic";
import Modal from "@/ui/modal/Modal";
import Button from "@/ui/button/Button";
import { getLabel } from "@/utils/helpers";

function ReviewsOverview({ reviews }) {
	const totalStars = reviews.reduce((acc, curr) => acc + curr?.stars, 0);
	const average = reviews.length > 0 ? totalStars / reviews.length : 5;

	return (
		<div className={style.reviewsOverview}>
			<div className={style.overview}>
				<div className={style.rating}>
					<span className={style.value}>
						{Number(average).toFixed(2)}
					</span>
					<div className={style.starsHolder}>
						<div
							className={style.stars}
							style={{ "--rating": Number(average).toFixed(2) }}
						></div>
						<div className={style.reviews}>
							<span>{reviews.length}</span>{" "}
							{getLabel(reviews.length, {
								singular: "recenzija",
								dual: "recenzije",
								plural: "recenzija",
							})}
						</div>
					</div>
				</div>
			</div>
			<div className={style.statisticHolder}>
				<ReviewsStatistic reviews={reviews} />
			</div>
			<div className={style.buttonHolder}>
				<Modal.Open opens="leaveReview">
					<Button className={style.btn} type="accent">
						Ostavi recenziju
					</Button>
				</Modal.Open>
			</div>
		</div>
	);
}

export default ReviewsOverview;
