import style from "./VideoBreadcrumb.module.css";
import Icon from "@/ui/icon/Icon";

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from "@/ui/breadcrumb/Breadcrumb";

function VideoBreadcrumb({ video }) {
	// course -> module -> lesson/ quiz
	return (
		<div className={style.breadcrumb}>
			<div className={style.item}>
				<div className={style.icon}>
					<Icon icon="fa-chalkboard" />
				</div>
				<span>{video.module.course.name}</span>
				<div className={style.separator}>
					<Icon icon="fa-chevron-right" />
				</div>
			</div>
			<div className={style.item}>
				<div className={style.icon}>
					<Icon icon="fa-layer-group" />
				</div>
				<span>{video.module.name}</span>
				<div className={style.separator}>
					<Icon icon="fa-chevron-right" />
				</div>
			</div>
			<div className={style.item}>
				<div className={style.icon}>
					<Icon icon="fa-film" />
				</div>
				<span>{video.name}</span>
			</div>
		</div>
	);
}

// function VideoBreadcrumb({ video }) {
// 	return (
// 		<Breadcrumb className={style.breadcrumb}>
// 			<BreadcrumbList>
// 				<BreadcrumbItem>
// 					<div className={style.icon}>
// 						<Icon icon="fa-chalkboard" />
// 					</div>
// 					<span>{video.module.course.name}</span>
// 				</BreadcrumbItem>
// 				<BreadcrumbSeparator />
// 				<BreadcrumbItem>
// 					<div className={style.icon}>
// 						<Icon icon="fa-layer-group" />
// 					</div>
// 					<span>{video.module.name}</span>
// 				</BreadcrumbItem>
// 				<BreadcrumbSeparator />
// 				<BreadcrumbItem>
// 					<div className={style.icon}>
// 						<Icon icon="fa-film" />
// 					</div>
// 					<span>{video.name}</span>
// 				</BreadcrumbItem>
// 			</BreadcrumbList>
// 		</Breadcrumb>
// 	);
// }

import { useMediaQuery } from "@/hooks/useMediaQuery";

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../dropdownMenu/DropdownMenu";

import { Link } from "react-router-dom";
import { useState } from "react";

const ITEMS_TO_DISPLAY = 3;

function BreadcrumbResponsive({ video }) {
	const [open, setOpen] = useState(false);
	const isDesktop = useMediaQuery("(min-width: 768px)");

	const items = [
		{ label: video.module.course.name },
		{ label: video.module.name },
		{ label: video.name },
	];

	return (
		<Breadcrumb className={style.breadcrumb}>
			<BreadcrumbList>
				<BreadcrumbItem className={style.item}>
					{items[0].label}
				</BreadcrumbItem>
				<BreadcrumbSeparator />
				{items.length > ITEMS_TO_DISPLAY ? (
					<>
						<BreadcrumbItem className={style.item}>
							{isDesktop ? (
								<DropdownMenu
									open={open}
									onOpenChange={setOpen}
								>
									<DropdownMenuTrigger
										className="flex items-center gap-1"
										aria-label="Toggle menu"
									>
										<Icon icon="fa-ellipsis-h" />
									</DropdownMenuTrigger>
									<DropdownMenuContent align="start">
										{items
											.slice(1, -2)
											.map((item, index) => (
												<DropdownMenuItem key={index}>
													<Link
														href={
															item.href
																? item.href
																: "#"
														}
													>
														{item.label}
													</Link>
												</DropdownMenuItem>
											))}
									</DropdownMenuContent>
								</DropdownMenu>
							) : (
								<div>Drawer</div>
							)}
						</BreadcrumbItem>
						<BreadcrumbSeparator />
					</>
				) : null}
				{items.slice(-ITEMS_TO_DISPLAY + 1).map((item, index) => (
					<BreadcrumbItem key={index}>
						{item.href ? (
							<>
								<BreadcrumbLink
									asChild
									className="max-w-20 truncate md:max-w-none"
								>
									<Link href={item.href}>{item.label}</Link>
								</BreadcrumbLink>
								<BreadcrumbSeparator />
							</>
						) : (
							<BreadcrumbPage className="max-w-20 truncate md:max-w-none">
								{item.label}
							</BreadcrumbPage>
						)}
					</BreadcrumbItem>
				))}
			</BreadcrumbList>
		</Breadcrumb>
	);
}

export default VideoBreadcrumb;
