import { useState } from "react";
import style from "./Home.module.css";

import IncompleteCourseCard from "@/features/courses/components/incompleteCourseCard/IncompleteCourseCard";

import Icon from "@/ui/icon/Icon";
import Breadcrumb from "@/ui/videoBreadcrumb/VideoBreadcrumb";
import Container from "@/layouts/container/Container";
import Button from "@/ui/button/Button";

import ContinueWatchingStatistics from "@/features/courses/components/continueWatchingStatistics/ContinueWatchingStatistics";
import CourseSwiper from "@/features/courses/components/courseSwiper/CourseSwiper";

import { Link } from "react-router-dom";

import "swiper/css";
import { useGetRecentCourses } from "@/features/courses/hooks/useGetRecentCourses";
import { useGetContinueWatching } from "@/features/courses/hooks/useGetContinueWatching";
import { useGetMostLikedCourses } from "@/features/courses/hooks/useGetMostLikedCourses";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import { errorToast, successToast } from "@/ui/toast/Toast";

function videoPercentComplete(video) {
	if (video?.user_activity?.current_second_in_video && video?.duration > 0)
		return Math.floor(
			(100 * video?.user_activity?.current_second_in_video) /
				video?.duration
		);
	return 0;
}

function Home() {
	const { courses, isSuccess, isPending, isError, error } =
		useGetRecentCourses();
	const continueWatchingQuery = useGetContinueWatching();

	const { courses: mostLikedCourses } = useGetMostLikedCourses();

	if (isError) {
		return <>Error: {JSON.stringify(error)}</>;
	}

	if (continueWatchingQuery.isError)
		return <>Error: {JSON.stringify(continueWatchingQuery.error)} </>;

	if (!isSuccess || !continueWatchingQuery.isSuccess)
		return <FullPageLoader />;

	return (
		<>
			<div className={style.background}>
				<Container>
					<div className={style.banner}>
						<div className={style.left}>
							<h2>NASTAVI GLEDATI:</h2>
							<h1>{continueWatchingQuery.data.video.name}</h1>
							<p>
								{continueWatchingQuery.data.video.description ||
									continueWatchingQuery.data.video.module
										.description}
							</p>
							<div className={style.buttonHolder}>
								<Button
									to={`/courses/${continueWatchingQuery.data.video.module.course.slug}/${continueWatchingQuery.data.video.module.slug}/${continueWatchingQuery.data.video.slug}/`}
									type="accent"
								>
									<span>Nastavi gledati</span>

									<Icon icon="fa-arrow-right-long" />
								</Button>
							</div>
						</div>
						<div className={style.right}>
							<div className={style.videoInfo}>
								<Breadcrumb
									video={continueWatchingQuery.data.video}
								/>

								<Link
									to={`/courses/${continueWatchingQuery.data.video.module.course.slug}/${continueWatchingQuery.data.video.module.slug}/${continueWatchingQuery.data.video.slug}/`}
									style={{
										"--background": `url(${continueWatchingQuery.data.video.thumbnail})`,
										"--video-percent": `${videoPercentComplete(
											continueWatchingQuery.data.video
										)}%`,
									}}
									className={style.video}
								></Link>
							</div>
							<ContinueWatchingStatistics
								courseProgress={
									continueWatchingQuery.data.courseProgress
								}
							/>
						</div>
					</div>
				</Container>
			</div>

			<Container>
				<CourseSwiper title="Skoro dodano" courses={courses} />
				<IncompleteCourseCard
					continueWatching={continueWatchingQuery.data}
				/>
				<CourseSwiper title="Najgledanije" courses={mostLikedCourses} />
			</Container>
		</>
	);
}

export default Home;
