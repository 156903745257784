import { useApiGet } from "@/hooks/useApi";
import { getCourse } from "../api";

export function useGetCourse({ courseSlug }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", courseSlug],
		fn: () => getCourse({ courseSlug }),
	});

	return { data, isPending, isSuccess, isError, error };
}
