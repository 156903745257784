import { Card, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import ConfirmationModal from "../ConfirmationModal";
import { useDeleteCourse } from "../../hooks/useDeleteCourse";
import { useNavigate } from "react-router-dom";
import { successToast } from "@/ui/toast/Toast";

export default function CourseActions({ course }) {
	const { deleteCourse } = useDeleteCourse({ courseID: course.id });
	const navigate = useNavigate();

	const deleteCourseAction = async function () {
		try {
			await deleteCourse();
			successToast(`Successfully deleted the course ${course.name}`);
			navigate("/admin/courses/");
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Card>
			<CardContent className="p-6">
				<div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-2">
					<ConfirmationModal
						action={deleteCourseAction}
						buttonText="Delete"
					>
						<Button variant="outline">Delete Course</Button>
					</ConfirmationModal>
					<Button>
						{course.status == "published" ? "View" : "Preview"}
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}
