import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import style from "./CourseTabs.module.css";

import AboutCourseSection from "@/features/video/components/aboutCourseSection/AboutCourseSection";
import ReviewSection from "@/features/reviews/ReviewSection";
import CommentSection from "@/features/comments/components/commentSection/CommentSection";
import TranscriptSection from "@/features/video/components/transcriptSection/TranscriptSection";

import { Link } from "react-router-dom";

function CourseTabs({ unit, currentVideoSecond }) {
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(0);

	const tabs = [
		{
			title: "O kursu",
			content: <AboutCourseSection unit={unit} />,
			path: "about",
		},
		{
			title: "Recenzije",
			content: <ReviewSection course={unit?.course} />,
			path: "reviews",
		},
		{
			title: "Postavi pitanje",
			content: <CommentSection video={unit.video} />,
			path: "questions",
		},
		{
			title: "Transkript",
			content: (
				<TranscriptSection
					video={unit.video}
					currentVideoSecond={currentVideoSecond}
				/>
			),
			path: "transcript",
		},
	];

	useEffect(() => {
		const path = location.hash.replace("#", "");
		const tabIndex = tabs.findIndex((tab) => tab.path === path);
		if (tabIndex !== -1) {
			setActiveTab(tabIndex);
		}
	}, [location.hash, tabs]);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<div className={style.tabs}>
			<div className={style.navigation}>
				{tabs.map((tab, index) => (
					<Link
						key={index}
						className={`${style.tabHeader} ${
							activeTab === index ? style.active : ""
						}`}
						onClick={() => handleTabClick(index)}
						to={`#${tab.path}`}
					>
						{tab.title}
					</Link>
				))}
			</div>
			<div className={style.tabContent}>
				{tabs.map(
					(tab, index) =>
						activeTab === index && (
							<div key={index}>{tab.content}</div>
						)
				)}
			</div>
		</div>
	);
}

export default CourseTabs;
