import React from "react";
import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/ui/Button";
import { Breadcrumbs, dashboardBreadcrumbs } from "../components/Breadcrumbs";

function Dashboard() {
	return (
		<>
			<PageTitle>Dashboard</PageTitle>
			<div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm">
				<div className="flex flex-col items-center gap-1 text-center py-16">
					<h3 className="text-2xl font-bold tracking-tight">
						Work in Progress
					</h3>
					<p className="text-sm text-muted-foreground">
						Dashboard Stats will Go Here
					</p>
					<Button className="mt-4">Add Product</Button>
				</div>
			</div>
		</>
	);
}

export default Dashboard;
