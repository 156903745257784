import { useApiSend } from "@/hooks/useApi";
import { saveCourse } from "../api";

export function useSaveCourse({ course }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["courses", course.slug, "save"],
		invalidateKeys: [["courses", "list"]],
		fn: () => saveCourse({ course }),
	});

	return { saveCourse: mutateAsync, isPending, error, isError };
}
