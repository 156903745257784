import { useApiSend } from "@/hooks/useApi";
import { useNavigate } from "react-router-dom";
import { login, loginWithGoogleOAuthCode } from "@/features/auth/api";
import { AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME } from "@/utils/constants";
import { errorToast, successToast } from "@/ui/toast/Toast";
import { useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";

export function useLoginWithGoogleOAuthCode() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["google-login"],
		fn: loginWithGoogleOAuthCode,
		invalidateKeys: [["whoami"]],
		resetKeys: [["whoami"]],
	});

	const loginMutate = async function ({ code, state }) {
		try {
			if (
				code == null ||
				state == null ||
				state != localStorage.getItem("google-state")
			) {
				throw new Error("invalid-state");
			}

			const res = await mutateAsync({ code });

			localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME, res.token);

			queryClient.resetQueries({ queryKey: ["whoami"] });

			if (res.has_completed_registration) {
				successToast(`Uspješno ste se prijavili`);
				navigate("/", { replace: true });
			} else {
				navigate("/register", { replace: true });
			}
		} catch (e) {
			errorToast(
				"Desila se greška pri prijavljivanju. Molimo pokušajte opet."
			);
			navigate("/login/", { replace: true });
		}
	};

	return { login: loginMutate, isPending, error, isError };
}
