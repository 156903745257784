import React, { useEffect, useCallback } from "react";
import style from "./NextButton.module.css";
import { useUpdateUserActivity } from "@/features/courses/hooks/useUpdateUserActivity";
import { useQueryClient } from "@tanstack/react-query";
import Button from "@/ui/button/Button";

const ACTIONS = {
	NEXT_QUESTION: "nextQuestion",
	FINISH: "finish",
};

function NextButton({ dispatch, answer, index, numQuestions, quiz }) {
	const { save } = useUpdateUserActivity({ unit: quiz });
	const queryClient = useQueryClient();

	const handleSaveAndDispatch = useCallback(async () => {
		const response = await save({
			duration: 0,
			unix_timestamp: Math.floor(Date.now() / 1000),
			current_second_in_video: 0,
			is_completed: true,
		});

		if (response?.shouldRefetchUnits) {
			queryClient.invalidateQueries({
				queryKey: ["courses", quiz.module.course.slug, "units", "list"],
			});
		}

		dispatch({ type: ACTIONS.FINISH });
	}, [save, queryClient, quiz.module.course.slug, dispatch]);

	const handleKeyDown = useCallback(
		async (event) => {
			if (event.key === "Enter" && answer) {
				if (index < numQuestions - 1) {
					dispatch({ type: ACTIONS.NEXT_QUESTION, payload: answer });
				} else if (index === numQuestions - 1) {
					await handleSaveAndDispatch();
				}
			}
		},
		[index, numQuestions, answer, dispatch, handleSaveAndDispatch]
	);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const handleClick = useCallback(() => {
		if (index < numQuestions - 1) {
			dispatch({ type: ACTIONS.NEXT_QUESTION, payload: answer });
		} else if (index === numQuestions - 1) {
			handleSaveAndDispatch();
		}
	}, [index, numQuestions, answer, dispatch, handleSaveAndDispatch]);

	return (
		<Button className={style.next} onClick={handleClick} disabled={!answer}>
			{index < numQuestions - 1 ? "Nastavi" : "Završi"}
		</Button>
	);
}

export default NextButton;
