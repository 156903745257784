import { Card, CardHeader, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { Link } from "react-router-dom";
import { Input } from "../ui/Input";
import { Progress } from "../ui/Progress";
import * as tus from "tus-js-client";
import { useState } from "react";

export default function VideoUnitUpload({ unit }) {
	const [isUploading, setIsUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [isDone, setIsDone] = useState(false);

	const [file, setFile] = useState(null);

	function startUpload() {
		if (isUploading) return;

		// Create a new tus upload
		var upload = new tus.Upload(file, {
			endpoint: "https://video.bunnycdn.com/tusupload",
			retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
			headers: {
				AuthorizationSignature:
					unit.video_info.bunny_authorization.signature,
				AuthorizationExpire:
					unit.video_info.bunny_authorization.expires,
				VideoId: unit.video_info.bunny_video_id,
				LibraryId: unit.video_info.bunny_library_id,
			},
			metadata: {
				filetype: file.type,
				title: unit.name,
				collection: unit.video_info.bunny_collection_id,
			},
			onError: function (error) {
				setIsUploading(false);
				alert(error);
			},
			onProgress: function (bytesUploaded, bytesTotal) {
				console.log(bytesUploaded, bytesTotal);
				if (bytesTotal > 0)
					setProgress(
						Math.round((100 * (100 * bytesUploaded)) / bytesTotal) /
							100
					);
			},
			onSuccess: function () {
				setFile(null);
				setIsDone(true);
			},
		});

		// Check if there are any previous uploads to continue.
		upload.findPreviousUploads().then(function (previousUploads) {
			// Found previous uploads so we select the first one.
			if (previousUploads.length) {
				upload.resumeFromPreviousUpload(previousUploads[0]);
			}

			// Start the upload
			upload.start();
			setIsDone(false);
			setIsUploading(true);
		});
	}

	return (
		<Card>
			<CardHeader className="px-4 py-6 sm:px-6 flex gap-2">
				<div className="space-y-0">
					<h2 className="text-base font-semibold text-gray-900">
						Upload
					</h2>
					<p className="max-w-2xl text-sm leading-6 text-gray-500">
						Select a file to start the upload
					</p>
				</div>

				<div className="ml-auto flex gap-2">
					<Input
						type="file"
						onChange={(e) => {
							if (e.target.files.length)
								setFile(e.target.files[0]);
							else setFile(null);

							// Allow reupload if the previous one finished
							if (isDone) setIsUploading(false);
						}}
						className="cursor-pointer [&_*]:cursor-pointer"
					/>
					<Button
						disabled={file == null || isUploading}
						onClick={startUpload}
					>
						Upload
					</Button>
				</div>
			</CardHeader>
			{isUploading && (
				<CardContent>
					{isDone ? (
						"Done!"
					) : (
						<div className="flex gap-2 items-center">
							{progress}% <Progress value={progress} />
						</div>
					)}
				</CardContent>
			)}
		</Card>
	);
}
