import { createVideoUnit } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useCreateVideoUnit({ courseID, moduleID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"units",
			"createVideo",
		],
		fn: (data) => createVideoUnit({ courseID, moduleID, unitData: data }),
		invalidateKeys: [
			["admin", "courses", courseID, "modules", moduleID, "units"],
		],
	});

	return { save: mutateAsync, isPending, error, isError };
}
