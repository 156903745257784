import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Link, NavLink, useParams } from "react-router-dom";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import BackButton from "@/features/admin/components/ui/BackButton";
import EditModuleForm from "../../components/courses/EditModuleForm";

import { useGetModule } from "../../hooks/useGetModule";

export default function EditModule() {
	let { courseID, moduleID } = useParams();

	const { isSuccess, module, isPending, isError, error } = useGetModule({
		courseID,
		moduleID,
	});

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton
						to={`/admin/courses/${courseID}/modules/${moduleID}/`}
					/>{" "}
					{isSuccess ? `Edit ${module.name}` : "Edit Module"}
				</span>
			</PageTitle>

			{isSuccess && (
				<EditModuleForm courseID={courseID} module={module} />
			)}
			{isPending && <Loader />}

			{isError && <QueryError error={error} />}
		</>
	);
}
