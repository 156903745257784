import { editUser } from "../api/users";
import { useApiSend } from "@/hooks/useApi";

export function useEditUser({ userUUID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [`admin_editUser_${userUUID}`],
		fn: (data) => editUser({ userUUID: userUUID, updatedUserData: data }),
		resetKeys: [[`admin_getUser_${userUUID}`]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
