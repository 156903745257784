import { useQueryClient } from "@tanstack/react-query";
import { deleteUnit } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useDeleteUnit({ courseID, moduleID, unitID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"units",
			unitID,
			"delete",
		],
		fn: () => deleteUnit({ courseID, moduleID, unitID }),
		invalidateKeys: [
			[
				"admin",
				"courses",
				courseID,
				"modules",
				moduleID,
				"units",
				"list",
			],
		],
	});

	return { deleteUnit: mutateAsync, isPending, error, isError };
}
