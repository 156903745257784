import style from "./ReviewsStatistic.module.css";

import Icon from "@/ui/icon/Icon";

function percentageWithStars(reviews, stars) {
	if (reviews?.length == 0) return 0;
	return Number(
		(reviews.filter((review) => review.stars == stars).length /
			reviews.length) *
			100
	).toFixed(0);
}

function ReviewsStatistic({ reviews }) {
	return (
		<div className={style.statistic}>
			<div className={style.header}>
				<div className={style.explanatory}>
					<Icon icon="fa-question-circle" />
				</div>
			</div>
			<div className={style.body}>
				{[5, 4, 3, 2, 1].map((stars) => (
					<div key={stars} className={style.progressHolder}>
						<span>
							{stars} <Icon icon="fa-star" />
						</span>
						<div className={style.progress}>
							<div
								style={{
									"--percentage":
										percentageWithStars(reviews, stars) +
										"%",
								}}
								className={style.fill}
							></div>
						</div>
						<span>{percentageWithStars(reviews, stars)}%</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default ReviewsStatistic;
