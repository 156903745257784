import style from "./Navigation.module.css";

import { NavLink, Link } from "react-router-dom";
import Icon from "@/ui/icon/Icon";

import { useUser } from "@/features/auth/hooks/useUser";

function Navigation() {
	const { user } = useUser();

	return (
		<nav className={style.nav}>
			<ul className={style.navList}>
				{user?.role === "admin" && (
					<li className={style.navItem}>
						<Link to="admin" className={style.navLink}>
							<Icon icon="fa-crown" />
							<span>Admin</span>
						</Link>
					</li>
				)}
				<li className={style.navItem}>
					<NavLink to="/" className={style.navLink}>
						<Icon icon="fa-house" />
						<span>Početna</span>
					</NavLink>
				</li>
				<li className={style.navItem}>
					<NavLink to="courses" className={style.navLink}>
						<Icon icon="fa-album-collection" />
						<span>Kursevi</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}

export default Navigation;
