import { useController, useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import InputError from "../ui/InputError";
import { Controller } from "react-hook-form";
import { MoveRightIcon, MoveLeftIcon, MoveLeft } from "lucide-react";

export default function FormMultiSelect({
	name,
	choices = [],
	label,
	placeholder = "",
	required = false,
}) {
	const {
		register,
		control,
		formState: { errors, isSubmitting },
	} = useFormContext();

	if (!label) label = capitalize(name);
	if (!placeholder) placeholder = "Select a value";

	const {
		field,
		fieldState: { error },
	} = useController({ name, control });

	const selectedChoices = choices.filter((choice) =>
		field.value.includes(choice.id)
	);

	const unselectedChoices = choices.filter(
		(choice) => !field.value.includes(choice.id)
	);

	const handleChoiceSelect = function (choice) {
		if (!field.value.includes(choice.id)) {
			field.onChange([...field.value, choice.id]);
		}
		field.onBlur();
	};

	const handleChoiceUnselect = function (choice) {
		if (field.value.includes(choice.id)) {
			field.onChange(field.value.filter((id) => id != choice.id));
		}
		field.onBlur();
	};

	return (
		<>
			<div>
				<Label htmlFor={field.name}>Available {label}</Label>
				<ul className="mt-1.5 border border-gray-200 divide-y divide-gray-200">
					{unselectedChoices.map((choice) => (
						<li
							className="px-4 py-2 flex justify-between hover:bg-gray-100 cursor-pointer"
							key={choice.id}
							onClick={() => handleChoiceSelect(choice)}
						>
							<span>{choice.name}</span>
							<MoveRightIcon className="w-5 h-5 text-primary" />
						</li>
					))}
				</ul>
			</div>
			<div>
				<Label htmlFor={name}>Selected {label}</Label>
				<ul className="mt-1.5 border border-gray-200 divide-y divide-gray-200">
					{selectedChoices.map((choice) => (
						<li
							className="px-4 py-2 flex justify-between hover:bg-gray-100 cursor-pointer"
							key={choice.id}
							onClick={() => handleChoiceUnselect(choice)}
						>
							<MoveLeftIcon className="w-5 h-5 text-primary" />
							<span>{choice.name}</span>
						</li>
					))}
				</ul>
				{error && <InputError>{error.message}</InputError>}
			</div>
		</>
	);
}

function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}
