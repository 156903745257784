import { useState } from "react";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/ui/select/Select";

function CourseSort({ selectedSortBy, setSelectedSortBy }) {
	return (
		<Select value={selectedSortBy} onValueChange={setSelectedSortBy}>
			<SelectTrigger>
				<SelectValue placeholder="Sortirajte" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Sortiraj po:</SelectLabel>
					<SelectItem value="date-desc">Najnovije</SelectItem>
					<SelectItem value="most-liked">Najdraže</SelectItem>
					{/*<SelectItem value="most-viewed">Najgledanije</SelectItem>*/}
					<SelectItem value="saved-first">Spašeni kursevi</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}

export default CourseSort;
