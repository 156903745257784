import { request } from "@/services/network";
import { sleep } from "@/utils/helpers";

// some functions to interact with the auth API for example

export async function getUsers() {
	return request({
		url: "/users/",
		method: "GET",
	}).then((data) => {
		return {
			users: data,
		};
	});
}

export async function getUser(userUUID) {
	return request({
		url: `/users/${encodeURIComponent(userUUID)}/`,
		method: "GET",
	}).then((data) => {
		return {
			user: data,
		};
	});
}

export async function editUser({ userUUID, updatedUserData }) {
	return request({
		url: `/users/${encodeURIComponent(userUUID)}/`,
		method: "POST",
		data: {
			username: updatedUserData.username,
			email: updatedUserData.email,
			phone_number: updatedUserData.phone_number,
			first_name: updatedUserData.first_name,
			last_name: updatedUserData.last_name,
		},
	})
		.then((data) => {
			return {
				success: true,
			};
		})
		.catch((error) => {
			throw error;
		});
}
