import { Card, CardHeader, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
	CheckCircleIcon,
	CheckIcon,
	CrossIcon,
	MoveDownIcon,
	MoveUpIcon,
	PencilIcon,
	TrashIcon,
	XCircleIcon,
	XIcon,
} from "lucide-react";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { Textarea } from "../ui/Textarea";
import { Switch } from "../ui/Switch";
import { errorToast, successToast } from "@/ui/toast/Toast";
import { useEditQuestions } from "../../hooks/useEditQuestions";

export default function QuizQuestions({ courseID, moduleID, unit }) {
	const [questions, setQuestions] = useState(unit.quiz_info.questions);

	useEffect(() => {
		setQuestions(unit.quiz_info.questions);
	}, [unit.quiz_info.questions]);

	const [editQuestions, setEditQuestions] = useState([]);
	const [isEditing, setIsEditing] = useState(false);

	const { save, isPending } = useEditQuestions({
		courseID,
		moduleID,
		unitID: unit.id,
	});

	function startEditing() {
		setEditQuestions(questions);
		setIsEditing(true);
	}

	function cancelEdits() {
		setEditQuestions(questions);
		setIsEditing(false);
	}

	async function saveEdits() {
		await save(editQuestions);
		successToast("Successfully saved the new questions");
		setIsEditing(false);
	}

	function addQuestion() {
		setEditQuestions([
			...editQuestions,
			{
				uuid: uuidv4(),
				question: "",
				answers: [
					{
						uuid: uuidv4(),
						answer: "",
						isCorrect: true,
					},
				],
			},
		]);
	}

	function updateQuestion(value, uuid) {
		setEditQuestions(
			editQuestions.map((question) => {
				if (question.uuid == uuid) return value;
				else return question;
			})
		);
	}

	function deleteQuestion(uuid) {
		setEditQuestions(
			editQuestions.filter((question) => question.uuid != uuid)
		);
	}

	function moveToIndex(idx, newIdx) {
		const item = editQuestions[idx];

		const otherItems = [
			...editQuestions.slice(0, idx),
			...editQuestions.slice(idx + 1),
		];

		setEditQuestions([
			...otherItems.slice(0, newIdx),
			item,
			...otherItems.slice(newIdx),
		]);
	}

	function moveUp(uuid) {
		const idx = editQuestions.findIndex((answer) => answer.uuid == uuid);
		const newIdx = idx - 1;
		if (newIdx >= 0) moveToIndex(idx, newIdx);
	}

	function moveDown(uuid) {
		const idx = editQuestions.findIndex((answer) => answer.uuid == uuid);
		const newIdx = idx + 1;
		if (newIdx < editQuestions.length) moveToIndex(idx, newIdx);
	}

	return (
		<div className="max-w-3xl space-y-3">
			<Card>
				<CardHeader className="px-4 py-6 sm:px-6 flex gap-2">
					<div className="space-y-0">
						<h2 className="text-lg font-semibold text-gray-900">
							Questions
						</h2>
						<p className="max-w-2xl text-sm leading-6 text-gray-500">
							Manage quiz questions
						</p>
					</div>
					<div className="ml-auto">
						{isEditing ? (
							<Button variant="outline" onClick={addQuestion}>
								Add Question
							</Button>
						) : (
							<Button variant="outline" onClick={startEditing}>
								Edit
							</Button>
						)}
					</div>
				</CardHeader>
				<CardContent className="px-0 py-0 [&:not(:empty)]:border-t border-gray-200 space-y-4 divide-y divide-gray-200">
					{isEditing
						? editQuestions.map((question, index) => (
								<EditQuizQuestion
									key={question.uuid}
									question={question}
									moveUp={() => moveUp(question.uuid)}
									moveDown={() => moveDown(question.uuid)}
									canMoveUp={index > 0}
									canMoveDown={
										index < editQuestions.length - 1
									}
									onChange={(value) =>
										updateQuestion(value, question.uuid)
									}
									onDelete={() =>
										deleteQuestion(question.uuid)
									}
								/>
						  ))
						: questions.map((question) => (
								<QuizQuestion
									key={question.uuid}
									question={question}
								/>
						  ))}

					{isEditing && (
						<div className="px-6 py-4 flex gap-2">
							<Button variant="outline" onClick={cancelEdits}>
								Cancel
							</Button>
							<Button className="ml-auto" onClick={saveEdits}>
								{isPending ? "Saving" : "Save"}
							</Button>
						</div>
					)}
				</CardContent>
			</Card>
		</div>
	);
}

function EditQuizQuestion({
	question,
	onChange,
	onDelete,
	moveUp,
	moveDown,
	canMoveUp,
	canMoveDown,
}) {
	function handleQuestionChange(e) {
		onChange({
			...question,
			question: e.target.value,
		});
	}

	function handleAnswerChange(e, uuid) {
		onChange({
			...question,
			answers: question.answers.map((answer) => {
				if (answer.uuid == uuid) {
					return {
						...answer,
						answer: e.target.value,
					};
				}

				return answer;
			}),
		});
	}

	function setCorrectAnswer(uuid) {
		onChange({
			...question,
			answers: question.answers.map((answer) => {
				if (answer.uuid == uuid) {
					return {
						...answer,
						isCorrect: true,
					};
				} else {
					return {
						...answer,
						isCorrect: false,
					};
				}
			}),
		});
	}

	function handleAnswerCorrectChange(value, uuid) {
		if (value == true) {
			setCorrectAnswer(uuid);
		} else {
			const allowUnselect = false;
			if (!allowUnselect) return;

			if (question.answers.length <= 1)
				errorToast("At least one answer must be correct");

			let idx =
				question.answers.findIndex(
					(question) => question.uuid == uuid
				) + 1;
			if (idx >= question.answers.length) idx = 0;
			setCorrectAnswer(question.answers[idx].uuid);
		}
	}

	function addAnswer(e) {
		onChange({
			...question,
			answers: [
				...question.answers,
				{
					uuid: uuidv4(),
					answer: "",
					isCorrect: question.answers.length == 0,
				},
			],
		});
	}

	function deleteAnswer(uuid) {
		if (question.answers.length < 2) return;

		const answer = question.answers.find((answer) => answer.uuid == uuid);

		// Remove the answer
		let answers = question.answers.filter((answer) => answer.uuid != uuid);

		// Ensure at least one answer remains correct
		if (answer.isCorrect) {
			answers = answers.map((answer, index) => {
				return {
					...answer,
					isCorrect: index == 0,
				};
			});
		}

		// Remove answer and set the first remaining one as correct
		onChange({
			...question,
			answers: answers,
		});
	}

	return (
		<div className="px-6 py-4 flex gap-4">
			<div className="space-y-3 w-full">
				<div>
					<Label>Question</Label>
					<Textarea
						onChange={handleQuestionChange}
						value={question.question}
					/>
				</div>

				{question.answers.map((answer, index) => (
					<div key={answer.uuid}>
						<Label>Answer {index + 1}</Label>

						<div className="flex items-center gap-2">
							<Input
								onChange={(e) =>
									handleAnswerChange(e, answer.uuid)
								}
								value={answer.answer}
							/>

							{question.answers.length >= 2 && (
								<Button
									variant="outline"
									onClick={() => deleteAnswer(answer.uuid)}
								>
									<TrashIcon className="w-4 h-4 text-gray-600" />
								</Button>
							)}

							<Switch
								checked={answer.isCorrect}
								onCheckedChange={(value) =>
									handleAnswerCorrectChange(
										value,
										answer.uuid
									)
								}
							/>
						</div>
					</div>
				))}

				<Button variant="outline" onClick={addAnswer}>
					Add Answer
				</Button>
			</div>
			<div className="ml-auto space-y-2 flex flex-col shrink">
				{canMoveUp && (
					<Button variant="outline" onClick={moveUp}>
						<MoveUpIcon className="mr-1.5 w-4 h-4 text-gray-600" />
						Up
					</Button>
				)}

				{canMoveDown && (
					<Button variant="outline" onClick={moveDown}>
						<MoveDownIcon className="mr-1.5 w-4 h-4 text-gray-600" />{" "}
						Down
					</Button>
				)}

				<Button variant="outline" onClick={onDelete}>
					<TrashIcon className="mr-1.5 w-4 h-4 text-gray-600" />{" "}
					Delete
				</Button>
			</div>
		</div>
	);
}

function QuizQuestion({ question }) {
	return (
		<div className="px-6 py-4 flex gap-2">
			<div>
				<h3 className="text-lg font-medium text-gray-900">
					{question.question}
				</h3>
				<ul className="text-base mt-1 space-y-1">
					{question.answers.map((answer) => (
						<li
							className="flex gap-2 items-center"
							key={answer.uuid}
						>
							{answer.answer}{" "}
							{answer.isCorrect ? (
								<CheckCircleIcon className="w-4 h-4 text-green-600" />
							) : (
								<XCircleIcon className="w-4 h-4 text-red-600" />
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
