import { useApiGet } from "@/hooks/useApi";
import { getCourses } from "@/features/admin/api/courses";

export function useGetCourses() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin", "courses", "list"],
		fn: getCourses,
	});

	const { courses } = data || { courses: [] };

	return { courses, isPending, isSuccess, isError, error };
}
