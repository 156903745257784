import { errorToast } from "@/ui/toast/Toast";
import { createSubscriptionOrder } from "../api";
import { useApiSend } from "@/hooks/useApi";

export function useSubscribe() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: createSubscriptionOrder,
	});

	const subscribe = async function () {
		const data = await mutateAsync();

		if (data?.checkout_url) window.location.href = data.checkout_url;
		else
			errorToast(
				"Desila se greška pri pravljenju linka za pretplatu. Pokušajte opet."
			);
	};

	return { subscribe, isPending, error, isError };
}
