import { useState } from "react";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/ui/select/Select";

// Filter Component
function ReviewsFilter() {
	const [selectedValue, setSelectedValue] = useState("most-recent");

	return (
		<Select value={selectedValue} onValueChange={setSelectedValue}>
			<SelectTrigger>
				<SelectValue placeholder="Sortirajte" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Sortiraj po:</SelectLabel>
					<SelectItem value="most-recent">Najnovije</SelectItem>
					<SelectItem value="highest-rated">
						Najbolje ocijenjeno
					</SelectItem>
					<SelectItem value="lowest-rated">
						Najslabije ocijenjeno
					</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}

export default ReviewsFilter;
