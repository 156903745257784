import { useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { mapDjangoValidationErrors } from "../../lib/utils";
import { Card, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { Link } from "react-router-dom";
import { slugify } from "../../lib/utils";
import { useEditModule } from "../../hooks/useEditModule";
import { successToast } from "@/ui/toast/Toast";
import FormInput from "../forms/FormInput";
import FormTextArea from "../forms/FormTextArea";

export default function EditModuleForm({ courseID, module }) {
	const navigate = useNavigate();

	const formMethods = useForm({
		values: {
			name: module.name,
			slug: module.slug,
			description: module.description,
		},
	});

	const {
		handleSubmit,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = formMethods;

	const watchName = watch("name");

	const { save, isPending, isError, error } = useEditModule({
		courseID: courseID,
		moduleID: module.id,
	});

	const onSubmit = async (data) => {
		// Don't resubmit
		if (isSubmitting) return;

		try {
			await save(data);
			navigate(`/admin/courses/${courseID}/modules/${module.id}/`);
			successToast("Uspješno spašene promjene");
		} catch (error) {
			mapDjangoValidationErrors({ error, setError });
		}
	};

	return (
		<Card>
			<CardContent className="pt-6">
				<FormProvider {...formMethods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="grid grid-cols-2 gap-6">
							<FormInput
								name="name"
								type="text"
								label="Name"
								required
							/>
							<FormInput
								name="slug"
								type="text"
								label="Slug"
								placeholder={slugify(watchName)}
							/>
							<FormTextArea
								name="description"
								label="Description"
							/>
						</div>
						<div className="mt-6 flex gap-2 justify-between">
							<Button variant="outline" asChild>
								<Link
									to={`/admin/courses/${courseID}/modules/${module.id}/`}
								>
									Cancel
								</Link>
							</Button>
							<Button>{isSubmitting ? "Saving" : "Save"}</Button>
						</div>
					</form>
				</FormProvider>
			</CardContent>
		</Card>
	);
}
