export default function VideoUnitVideoPreview({ unit }) {
	return (
		<div>
			{unit.video_info.bunny_status == "Finished" ? (
				<div
					className="overflow-hidden shadow-lg rounded-lg border-input"
					style={{ position: "relative", paddingTop: "56.25%" }}
				>
					<iframe
						src={`https://iframe.mediadelivery.net/embed/${unit.video_info.bunny_library_id}/${unit.video_info.bunny_video_id}`}
						loading="lazy"
						style={{
							border: "none",
							position: "absolute",
							top: "0",
							height: "100%",
							width: "100%",
						}}
						allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
						allowfullscreen="true"
					></iframe>
				</div>
			) : (
				""
			)}
		</div>
	);
}
