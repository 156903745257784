import { NavLink } from "react-router-dom";

function SidebarLink({
	url,
	name,
	Icon,
	variant = "desktop",
	className,
	closeSidebar,
}) {
	if (variant == "mobile") {
		return (
			<NavLink
				to={url}
				className={({ isActive }) => {
					return (
						"mx-[-0.65rem] flex items-center gap-2 rounded-lg px-2 py-2 text-base" +
						" " +
						className +
						" " +
						(isActive
							? "bg-zinc-200 text-zinc-700"
							: " text-zinc-500 hover:text-zinc-700 hover:bg-zinc-200/50")
					);
				}}
				onClick={closeSidebar}
			>
				<Icon className="h-[1.15rem] w-[1.15rem]" />
				{name}
			</NavLink>
		);
	}

	return (
		<NavLink
			to={url}
			className={({ isActive }) => {
				return (
					"flex items-center gap-2 rounded-lg px-3 py-3 text-sm font-medium" +
					" " +
					className +
					" " +
					(isActive
						? "bg-zinc-200 text-zinc-700"
						: " text-zinc-500 hover:text-zinc-700 hover:bg-zinc-200/50")
				);
			}}
		>
			<Icon className="h-[1.15rem] w-[1.15rem]" />
			{name}
		</NavLink>
	);
}

export default SidebarLink;
