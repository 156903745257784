import { useApiSend } from "@/hooks/useApi";
import { useNavigate } from "react-router-dom";
import { login } from "@/features/auth/api";
import { AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME } from "@/utils/constants";
import { errorToast, successToast } from "@/ui/toast/Toast";
import { useQueryClient } from "@tanstack/react-query";

export function useLogin() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["login"],
		fn: login,
		invalidateKeys: [["whoami"]],
		resetKeys: [["whoami"]],
	});

	const loginMutate = async function (data) {
		const res = await mutateAsync(data);

		localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME, res.token);

		queryClient.resetQueries({ queryKey: ["whoami"] });

		if (res.has_completed_registration) {
			navigate("/", { replace: true });
		} else {
			navigate("/register", { replace: true });
		}
	};

	return { login: loginMutate, isPending, error, isError };
}
