import { Card, CardHeader, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { Link } from "react-router-dom";

export default function VideoUnitInfo({ unit }) {
	return (
		<Card>
			<CardHeader className="px-4 py-6 sm:px-6 flex gap-2">
				<div className="space-y-0">
					<h2 className="text-base font-semibold text-gray-900">
						Video details
					</h2>
					<p className="max-w-2xl text-sm leading-6 text-gray-500">
						Information about the video
					</p>
				</div>
				<div className="ml-auto">
					{/*<Link to={`edit/`}>
						<Button variant="outline">Edit</Button>
					</Link>*/}
				</div>
			</CardHeader>
			<CardContent className="border-t border-gray-200 p-0">
				<dl className="divide-y divide-gray-200">
					<VideoUnitInfoRow
						label="Bunny Video ID"
						value={unit.video_info.bunny_video_id}
					/>
					<VideoUnitInfoRow
						label="Status"
						value={unit.video_info.bunny_status}
					/>
					<VideoUnitInfoRow
						label="Encode Progress"
						value={`${unit.video_info.bunny_encode_progress}%`}
					/>
					<VideoUnitInfoRow
						label="Video Duration"
						value={`${unit.video_info.bunny_duration} seconds`}
					/>
				</dl>
			</CardContent>
		</Card>
	);
}

function VideoUnitInfoRow({ label, value }) {
	return (
		<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
			<dt className="text-sm font-medium text-gray-900 flex items-center">
				{label}
			</dt>
			<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
				{value}
			</dd>
		</div>
	);
}
