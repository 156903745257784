import { useApiGet } from "@/hooks/useApi";
import { getContinueWatching } from "../api";

export function useGetContinueWatching() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", "continue-watching"],
		fn: getContinueWatching,
	});

	return { data, isPending, isSuccess, isError, error };
}
