import { useApiSend } from "@/hooks/useApi";
import { updateUserActivity } from "../api";

export function useUpdateUserActivity({ unit }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"courses",
			unit.module.course.slug,
			"modules",
			unit.module.slug,
			"units",
			unit.slug,
			"activity",
		],
		fn: (data) => updateUserActivity({ unit, data }),
	});

	return { save: mutateAsync, isPending, error, isError };
}
