import { useFormContext } from "react-hook-form";
import Label from "./Label";
import InputError from "./InputError";
import { useState, useRef } from "react";

import Icon from "@/ui/icon/Icon";

import { Controller } from "react-hook-form";
import styles from "./FormImage.module.css";

export default function FormImage({
	name,
	label,
	initialPreviewImageURL = null,
	...props
}) {
	const {
		control,
		setValue,
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	const imageInputRef = useRef();
	const [previewImageURL, setPreviewImageURL] = useState(
		initialPreviewImageURL
	);
	const [clearImage, setClearImage] = useState(false);

	const handleImageChange = function (e) {
		if (e.target.files && e.target.files[0]) {
			setValue(`${name}_clear`, false);
			setClearImage(false);
			let reader = new FileReader();
			reader.onload = function (ev) {
				setPreviewImageURL(ev.target.result);
			}.bind(this);
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const handleImageRemove = function () {
		imageInputRef.current.value = null;
		setValue(`${name}_clear`, true);
		setClearImage(true);
		setPreviewImageURL(null);
	};

	register(`${name}_clear`);

	return (
		<div>
			<Controller
				control={control}
				name={name}
				render={({ field }) => {
					return (
						<>
							<div className={styles.container}>
								{previewImageURL && (
									<div className={styles.imageWrapper}>
										<img
											src={previewImageURL}
											className={styles.image}
										/>
										<div
											onClick={handleImageRemove}
											className={styles.overlay}
										>
											<div className={styles.icoHolder}>
												<Icon icon="fa-times" />
											</div>
										</div>
									</div>
								)}

								<input
									type="file"
									className={styles.fileInput}
									id={name}
									ref={imageInputRef}
									onChange={(e) => {
										field.onChange(e.target.files[0]);
										handleImageChange(e);
									}}
									{...props}
								/>
								<label
									htmlFor={name}
									className={styles.fileInputLabel}
								>
									{!previewImageURL
										? "Odaberite sliku"
										: "Promijeni sliku"}
								</label>
							</div>
						</>
					);
				}}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}
