import { deleteCourse } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useDeleteCourse({ courseID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["admin", "courses", courseID, "delete"],
		fn: () => deleteCourse({ courseID }),
		invalidateKeys: [["admin", "courses", courseID, "list"]],
	});

	return { deleteCourse: mutateAsync, isPending, error, isError };
}
