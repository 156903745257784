import { Card, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import ConfirmationModal from "../ConfirmationModal";
import { useDeleteModule } from "../../hooks/useDeleteModule";
import { useNavigate } from "react-router-dom";
import { successToast } from "@/ui/toast/Toast";

export default function ModuleActions({ courseID, module }) {
	const { deleteModule } = useDeleteModule({
		courseID: courseID,
		moduleID: module.id,
	});
	const navigate = useNavigate();

	const deleteModuleAction = async function () {
		try {
			await deleteModule();
			successToast(`Successfully deleted the module ${module.name}`);
			navigate(`/admin/courses/${courseID}/modules/`);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Card>
			<CardContent className="p-6">
				<div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-2">
					<ConfirmationModal
						action={deleteModuleAction}
						buttonText="Delete"
					>
						<Button variant="outline">Delete Module</Button>
					</ConfirmationModal>
				</div>
			</CardContent>
		</Card>
	);
}
