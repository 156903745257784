import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import Icon from "@/ui/icon/Icon";

import style from "./Checkbox.module.css";

const Checkbox = React.forwardRef(function Checkbox(
	{ className, ...props },
	ref
) {
	return (
		<CheckboxPrimitive.Root
			ref={ref}
			className={`${style.checkboxRoot} ${className}`}
			{...props}
		>
			<CheckboxPrimitive.Indicator className={style.checkboxIndicator}>
				<Icon icon="fa-check" className={style.checkIcon} />
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	);
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
