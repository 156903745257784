import { request } from "@/services/network";
import { sleep } from "@/utils/helpers";
import { format } from "date-fns";

export async function getCourses() {
	return request({
		url: "/admin/courses/",
		method: "GET",
	}).then((data) => {
		return {
			courses: data,
		};
	});
}

export async function getCourse({ courseID }) {
	return request({
		url: `/admin/courses/${courseID}/`,
		method: "GET",
	}).then((data) => {
		return {
			course: data,
		};
	});
}

export async function deleteCourse({ courseID }) {
	return request({
		url: `/admin/courses/${courseID}/`,
		method: "DELETE",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function createCourse({ courseData }) {
	return request({
		url: `/admin/courses/`,
		method: "POST",
		data: {
			name: courseData.name,
		},
	}).then((data) => {
		return {
			success: true,
			course: data.course,
		};
	});
}

export async function editCourse({ courseID, updatedCourseData }) {
	return request({
		url: `/admin/courses/${courseID}/`,
		method: "POST",
		headers: {
			"Content-Type": "multipart/form-data",
		},
		data: {
			name: updatedCourseData.name,
			slug: updatedCourseData.slug,
			status: updatedCourseData.status,
			date_published: format(
				updatedCourseData.date_published,
				"yyyy-MM-dd"
			),
			description: updatedCourseData.description,
			thumbnail: updatedCourseData.thumbnail ?? null,
			thumbnail_clear: updatedCourseData.thumbnail_clear,
			categories: updatedCourseData.categories,
			lecturers: updatedCourseData.lecturers,
		},
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function getCategories() {
	return request({
		url: "/admin/categories/",
		method: "GET",
	}).then((data) => {
		return {
			categories: data,
		};
	});
}

export async function getLecturers() {
	return request({
		url: "/admin/lecturers/",
		method: "GET",
	}).then((data) => {
		return {
			lecturers: data,
		};
	});
}

export async function getModules({ courseID }) {
	return request({
		url: `/admin/courses/${courseID}/modules/`,
		method: "GET",
	}).then((data) => {
		return {
			modules: data,
		};
	});
}

export async function createModule({ courseID, moduleData }) {
	return request({
		url: `/admin/courses/${courseID}/modules/`,
		method: "POST",
		data: {
			name: moduleData.name,
		},
	}).then((data) => {
		return {
			success: true,
			module: data.module,
		};
	});
}

export async function reorderModules({ courseID, updatedModulesList }) {
	return request({
		url: `/admin/courses/${courseID}/modules/reorder/`,
		method: "POST",
		data: {
			modules: updatedModulesList,
		},
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function getModule({ courseID, moduleID }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}`,
		method: "GET",
	}).then((data) => {
		return {
			module: data,
		};
	});
}

export async function editModule({ courseID, moduleID, updatedModuleData }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/`,
		method: "POST",
		data: {
			name: updatedModuleData.name,
			slug: updatedModuleData.slug,
			description: updatedModuleData.description,
		},
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function deleteModule({ courseID, moduleID }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/`,
		method: "DELETE",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function getUnits({ courseID, moduleID }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/`,
		method: "GET",
	}).then((data) => {
		return {
			units: data,
		};
	});
}

export async function reorderUnits({ courseID, moduleID, updatedUnitsList }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/reorder/`,
		method: "POST",
		data: {
			units: updatedUnitsList,
		},
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function getUnit({ courseID, moduleID, unitID }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/${unitID}/`,
		method: "GET",
	}).then((data) => {
		return {
			unit: data,
		};
	});
}

export async function createVideoUnit({ courseID, moduleID, unitData }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/`,
		method: "POST",
		data: {
			name: unitData.name,
			unit_type: "video",
		},
	}).then((data) => {
		return {
			success: true,
			unit: data.unit,
		};
	});
}

export async function createQuizUnit({ courseID, moduleID, unitData }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/`,
		method: "POST",
		data: {
			name: unitData.name,
			unit_type: "quiz",
		},
	}).then((data) => {
		return {
			success: true,
			unit: data.unit,
		};
	});
}

export async function deleteUnit({ courseID, moduleID, unitID }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/${unitID}/`,
		method: "DELETE",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function editUnit({
	courseID,
	moduleID,
	unitID,
	updatedUnitData,
}) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/${unitID}/`,
		method: "POST",
		data: {
			name: updatedUnitData.name,
			slug: updatedUnitData.slug,
			description: updatedUnitData.description,
		},
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function editQuestions({ courseID, moduleID, unitID, questions }) {
	return request({
		url: `/admin/courses/${courseID}/modules/${moduleID}/units/${unitID}/questions/`,
		method: "POST",
		data: {
			questions: questions,
		},
	}).then((data) => {
		return {
			success: true,
		};
	});
}
