import axios from "axios";
import {
	BASE_URL,
	AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME as TOKEN_NAME,
} from "@/utils/constants";

const client = axios.create({
	baseURL: BASE_URL,
	formSerializer: {
		indexes: null,
	},
	paramsSerializer: {
		indexes: null,
	},
});

export const request = async (options) => {
	const token = localStorage.getItem(TOKEN_NAME) || "";

	// Set the authorization header
	if (token !== "") {
		options.headers = {
			...options.headers,
			Authorization: `Bearer ${token}`,
		};
	}

	const onSuccess = (response) => {
		return response?.data;
	};

	const onError = (error) => {
		return Promise.reject(error);
	};

	options.timeout = 5000;

	return client(options).then(onSuccess).catch(onError);
};
