import style from "./RegisterOTPStep.module.css";

import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";
import FormOTP from "@/features/forms/components/FormOTP";
import { useCheckVerificationCode } from "../../hooks/useCheckVerificationCode";
import Spinner from "@/features/forms/components/Spinner";
import { errorToast } from "@/ui/toast/Toast";

import envelopeBack from "@/assets/images/envelope-back.svg";
import envelopeFront from "@/assets/images/envelope-front.svg";

import Icon from "@/ui/icon/Icon";

import Button from "@/ui/button/Button";

export default function RegisterOTPStep({
	email,
	code,
	setCode,
	prevStep,
	nextStep,
}) {
	const formMethods = useFormWithValidation({
		defaultValues: {
			code: code,
		},
	});
	const { checkCode } = useCheckVerificationCode();
	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;

	const onSubmit = async function (data) {
		try {
			await checkCode({
				email: email,
				code: data.code,
			});
		} catch (e) {
			if (e?.response?.data?.errors?.email?.length) {
				errorToast(e.response.data.errors.email[0]?.message);
				prevStep();
			}
			throw e;
		}
		setCode(data.code);
		nextStep();
	};

	return (
		<div className={style.container}>
			<div className={style.back}>
				<Icon icon="fa-arrow-left" />
				<span onClick={prevStep}>Nazad</span>
			</div>
			<div className={style.header}>
				<div className={style.letter}>
					<div className={style.emailAnimation}>
						<img src={envelopeBack} className={style.envBack} />
						<img src={envelopeFront} className={style.envFront} />

						<div className={style.envelope}>
							<Icon icon="fa-key-skeleton" />
							<span>***</span>
						</div>
					</div>
				</div>
				<h2>Verifkikacioni kod</h2>

				<p>
					Poslali smo verifikacijski kod na <span>{email}</span>
				</p>
			</div>

			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={style.inputHolder}>
						<FormOTP name="code" />
					</div>
					<div className={style.footer}>
						<div className={style.buttonHolder}>
							<Button type="accent">
								{isSubmitting ? <Spinner /> : "Nastavi"}
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	);
}
