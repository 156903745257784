import { getUnit } from "../api/courses";
import { useApiGet } from "@/hooks/useApi";

export function useGetUnit({ courseID, moduleID, unitID }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"units",
			unitID,
		],
		fn: () => getUnit({ courseID, moduleID, unitID }),
		options: {
			refetchInterval: 1500,
		},
	});

	const { unit } = data || { unit: {} };

	return { unit, isPending, isSuccess, isError, error };
}
