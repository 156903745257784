import style from "./ContinueWatchingStatistics.module.css";
import Icon from "@/ui/icon/Icon";

function ContinueWatchingStatistics({ courseProgress }) {
	const total_units =
		courseProgress.total_videos + courseProgress.total_quizes;
	const units_completed =
		courseProgress.videos_completed + courseProgress.quizes_completed;

	const units_percent = Math.round((100 * units_completed) / total_units);

	return (
		<div className={style.statistics}>
			<div
				className={style.circleProgress}
				style={{ "--percent-size": `${units_percent}%` }}
			>
				<div className={style.circle}>
					<div className={style.content}>
						<div className={style.percent}>
							<span>{units_percent}</span>
							<span>%</span>
						</div>
						<div className={style.progress}>
							<span>{units_completed}</span>
							<span>
								<Icon icon="fa-slash-forward" />
							</span>
							<span>{total_units}</span>
						</div>
					</div>
				</div>
			</div>
			<div className={style.progressInfo}>
				<div className={style.individualProgress}>
					<div className={style.percent}>
						<span>
							{courseProgress.total_videos > 0
								? Math.round(
										(100 *
											courseProgress.videos_completed) /
											courseProgress.total_videos
								  )
								: 100}
						</span>
						<span>%</span>
					</div>
					<span className={style.label}>Video</span>
				</div>
				<div className={style.individualProgress}>
					<div className={style.percent}>
						<span>
							{courseProgress.total_quizes > 0
								? Math.round(
										(100 *
											courseProgress.quizes_completed) /
											courseProgress.total_quizes
								  )
								: 100}
						</span>
						<span>%</span>
					</div>
					<span className={style.label}>Kviz</span>
				</div>
			</div>
		</div>
	);
}

export default ContinueWatchingStatistics;
