import { useMemo, useState } from "react";
import style from "./CourseCategoryCards.module.css";
import Icon from "@/ui/icon/Icon";

const categoryColors = {
	biznis: {
		color: "purple",
		icon: "fa-briefcase-blank",
	},
	tehnologija: {
		color: "blue",
		icon: "fa-desktop",
	},
	kreativa: {
		color: "orange",
		icon: "fa-palette",
	},
};

function CourseCategoryCards({
	categories,
	selectedCategory,
	setSelectedCategory,
}) {
	const categoriesWithColors = useMemo(() => {
		return categories
			.filter((category) => category.slug in categoryColors)
			.map((category) => {
				return {
					...category,
					...categoryColors[category.slug],
				};
			});
	}, [categories]);

	return (
		<>
			{categoriesWithColors.map((category) => (
				<CategoryCard
					key={category.name}
					category={category}
					isSelected={selectedCategory === category.slug}
					onSelect={() =>
						setSelectedCategory(
							category.slug === selectedCategory
								? null
								: category.slug
						)
					}
				/>
			))}
		</>
	);
}

function CategoryCard({ category, isSelected, onSelect }) {
	const { name, color, icon, courseCount } = category;
	const isDisabled = courseCount === 0;

	return (
		<div
			className={`${style.card} ${isSelected ? style.active : ""} ${
				isDisabled ? style.disabled : ""
			}`}
			onClick={!isDisabled ? onSelect : null}
		>
			<div
				style={{ "--color": color }}
				className={style.background}
			></div>
			<div className={style.info}>
				<div className={style.name}>
					<div className={style.icoHolder}>
						<Icon icon={icon} />
					</div>
					<h3>{name}</h3>
				</div>
				<div className={style.count}>
					<span>kurseva:</span>
					<span>{courseCount}</span>
				</div>
			</div>
		</div>
	);
}

export default CourseCategoryCards;
