import React from "react";
import { LoginForm } from "@/features/auth/components/LoginForm";

import { Link, Navigate } from "react-router-dom";

import promoVideo from "@/assets/preview.mp4";

import style from "./Login.module.css";
import Logo from "@/ui/logo/Logo";
import Icon from "@/ui/icon/Icon";

import { useUser } from "@/features/auth/hooks/useUser";

function Login() {
	const { isLoggedIn } = useUser();

	if (isLoggedIn) return <Navigate to="/" />;

	return (
		<div className={style.grid}>
			<div className={style.left}>
				<div className={style.header}>
					<Logo />
					<Link to="/register" className={style.createAccount}>
						<span>Registruj se</span>
						<Icon icon="fa-user" />
					</Link>
				</div>
				<div className={style.loginFormHolder}>
					<LoginForm />
				</div>

				<div className={style.footer}>
					<p className={style.rights}>
						&copy; {new Date().getFullYear()} Dekamas. Sva prava
						zadržana.
					</p>
				</div>
			</div>

			<div className={style.other}>
				<PromoVideoPreview />
			</div>
		</div>
	);
}

function PromoVideoPreview() {
	return (
		<div className={style.promo}>
			<video
				className={style.video}
				autoPlay
				muted
				loop
				playsInline
				src={promoVideo}
			/>
		</div>
	);
}

export default Login;
