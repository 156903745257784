import { whoami } from "@/features/auth/api";
import { useApiGet } from "@/hooks/useApi";

export function useUser() {
	const {
		data,
		isPending: isLoading,
		error,
	} = useApiGet({
		key: ["whoami"],
		fn: whoami,
		options: {
			staleTime: 60 * 1000,
			gcTime: 5 * 60 * 1000,
		},
	});

	const { isLoggedIn, user } = data || { isLoggedIn: false, user: null };

	return { isLoggedIn, isLoading, user, error };
}
