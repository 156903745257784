import { useApiGet } from "@/hooks/useApi";
import { getUnitComments } from "../api";

export function useGetUnitComments({ unit }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: [
			"comments",
			"courses",
			unit.module.course.slug,
			"modules",
			unit.module.slug,
			"units",
			unit.slug,
		],
		fn: () => getUnitComments({ unit }),
	});

	const { comments } = data || { comments: [] };

	return { comments, isPending, isSuccess, isError, error };
}
