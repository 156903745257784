import { useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { mapDjangoValidationErrors } from "../../lib/utils";
import { Card, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { Label } from "../ui/Label";
import InputError from "../ui/InputError";
import { Input } from "../ui/Input";
import { Link } from "react-router-dom";
import { slugify } from "../../lib/utils";
import { useEditCourse } from "../../hooks/useEditCourse";
import { successToast } from "@/ui/toast/Toast";
import FormDatePicker from "../forms/FormDatePicker";
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";
import FormTextArea from "../forms/FormTextArea";
import FormImage from "../forms/FormImage";
import FormMultiSelect from "../forms/FormMultiSelect";
import FormHTML from "../forms/FormHTML";

export default function EditCourseForm({
	course,
	availableCategories,
	availableLecturers,
}) {
	const navigate = useNavigate();

	console.log(availableCategories);

	const formMethods = useForm({
		values: {
			name: course.name,
			slug: course.slug,
			status: course.status,
			date_published: course.date_published,
			description: course.description,
			thumbnail_clear: false,
			categories: course.categories.map((category) => category.id),
			lecturers: course.lecturers.map((lecturer) => lecturer.id),
		},
	});

	const {
		handleSubmit,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = formMethods;

	const watchName = watch("name");

	const { save, isPending, isError, error } = useEditCourse({
		courseID: course.id,
	});

	const onSubmit = async (data) => {
		// Don't resubmit
		if (isSubmitting) return;

		try {
			console.log(data);
			await save(data);
			navigate(`/admin/courses/${course.id}/`);
			successToast("Uspješno spašene promjene");
		} catch (error) {
			mapDjangoValidationErrors({ error, setError });
		}
	};

	return (
		<Card>
			<CardContent className="pt-6">
				<FormProvider {...formMethods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="grid grid-cols-2 gap-6">
							<FormInput
								name="name"
								type="text"
								label="Name"
								required
							/>
							<FormInput
								name="slug"
								type="text"
								label="Slug"
								placeholder={slugify(watchName)}
							/>
							<FormSelect
								name="status"
								label="Status"
								placeholder="Select a status"
								choices={[
									{
										name: "Draft",
										value: "draft",
									},
									{
										name: "Published",
										value: "published",
									},
								]}
								required
							/>
							<FormDatePicker
								name="date_published"
								label="Date Published"
							/>
							<FormHTML name="description" label="Description" />
							<FormImage
								name="thumbnail"
								label="Thumbnail"
								initialPreviewImageURL={course.thumbnail}
							/>
							<FormMultiSelect
								name="categories"
								label="Categories"
								choices={availableCategories}
							/>
							<FormMultiSelect
								name="lecturers"
								label="Lecturers"
								choices={availableLecturers}
							/>
						</div>
						<div className="mt-6 flex gap-2 justify-between">
							<Button variant="outline" asChild>
								<Link to={`/admin/courses/${course.id}/`}>
									Cancel
								</Link>
							</Button>
							<Button>{isSubmitting ? "Saving" : "Save"}</Button>
						</div>
					</form>
				</FormProvider>
			</CardContent>
		</Card>
	);
}
