import { useApiSend } from "@/hooks/useApi";
import { likeComment } from "../api";
import { useQueryClient } from "@tanstack/react-query";

export function useLikeComment({ comment }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: () => likeComment({ comment }),
		invalidateKeys: [["comments"]],
	});

	return { like: mutateAsync, isPending, error, isError };
}
