import { useState } from "react";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";

import FormInput from "@/features/forms/components/FormInput";
import InputPassword from "@/features/forms/components/InputPassword";
import InputPhone from "@/features/forms/components/InputPhone";
import Spinner from "@/features/forms/components/Spinner";
import Checkbox from "@/features/forms/components/Checkbox";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";
import { errorToast } from "@/ui/toast/Toast";

import style from "./RegisterDetailsStep.module.css";

import { useRegister } from "../../hooks/useRegister";

function RegisterDetailsStep({ email, code, prevStep, nextStep, emailStep }) {
	const [terms, setTerms] = useState(false);

	const formMethods = useFormWithValidation({
		defaultValues: {
			username: email && email.split("@")[0],
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;

	const { register } = useRegister();

	const onSubmit = async function (data) {
		console.log("Calling submit!");
		try {
			await register({
				email: email,
				code: code,
				...data,
			});
		} catch (e) {
			if (e?.response?.data?.errors?.email?.length) {
				errorToast(e.response.data.errors.email[0]?.message);
				emailStep();
			} else if (e?.response?.data?.errors?.code?.length) {
				errorToast(e.response.data.errors.code[0]?.message);
				prevStep();
			}
			throw e;
		}

		nextStep();
	};

	return (
		<div className={style.content}>
			<div className={style.header}>
				<Icon icon="fa-user" />
				<h2>Unos personalnih informacija</h2>
			</div>
			<FormProvider {...formMethods}>
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<FormInput
						name="username"
						autoComplete="username"
						label="Korisničko ime"
						placeholder="korisnik123"
					/>
					<FormInput
						name="first_name"
						label="Ime"
						placeholder="Unesi ime"
					/>
					<FormInput
						name="last_name"
						label="Prezime"
						placeholder="Unesi prezime"
					/>
					<InputPhone
						name="phone_number"
						label="Broj telefona"
						placeholder="Unesi broj telefona"
					/>
					<div className={style.passwordHolder}>
						<InputPassword
							name="password1"
							autoComplete="new-password"
							label="Lozinka"
							placeholder="Unesi lozinku"
						/>
						<InputPassword
							name="password2"
							label="Ponovi lozinku"
							placeholder="Ponovo unesi lozinku"
						/>
					</div>
					<div className={style.termsOfUse}>
						<Checkbox
							id="terms"
							checked={terms}
							onCheckedChange={(val) => setTerms(val)}
						/>
						<label htmlFor="terms">
							Prihvatam sve <span>uslove korištenja</span>
						</label>
					</div>
					<div className={style.buttonHolder}>
						<Button disabled={!terms || isSubmitting} type="accent">
							{isSubmitting ? <Spinner /> : "Nastavi"}
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
}

export default RegisterDetailsStep;
