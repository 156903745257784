import { deleteModule } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useDeleteModule({ courseID, moduleID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"delete",
		],
		fn: () => deleteModule({ courseID, moduleID }),
		invalidateKeys: [["admin", "courses", courseID, "modules", "list"]],
	});

	return { deleteModule: mutateAsync, isPending, error, isError };
}
