import { useApiGet } from "@/hooks/useApi";
import { getUnit } from "../api";

export function useGetUnit({ courseSlug, moduleSlug, unitSlug }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", courseSlug, "modules", moduleSlug, "units", unitSlug],
		fn: () => getUnit({ courseSlug, moduleSlug, unitSlug }),
	});

	return { data, isPending, isSuccess, isError, error };
}
