import { editSettings } from "@/features/auth/api";
import { useApiSend } from "@/hooks/useApi";

export function useEditSettings() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: (data) => editSettings({ updatedSettings: data }),
		invalidateKeys: [["whoami"]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
