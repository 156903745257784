import { ChevronRightIcon, GripIcon } from "lucide-react";

// Needs name, priority, id
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	MouseSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	useSortable,
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { Link, useNavigate } from "react-router-dom";

export default function ReorderableList({
	items,
	setItems,
	itemBaseURL = null,
}) {
	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: 0,
				tolerance: 5,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragEnd(event) {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems((items) => {
				// prettier-ignore
				const oldIndex = items.findIndex((item) => item.id == active.id);
				const newIndex = items.findIndex((item) => item.id == over.id);

				return arrayMove(items, oldIndex, newIndex);
			});
		}
	}

	return (
		<ul
			role="list"
			className="divide-y divide-gray-200 overflow-hidden border border-gray-200 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
		>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<SortableContext
					items={items}
					strategy={verticalListSortingStrategy}
				>
					{items.length > 0 ? (
						items.map((item, index) => (
							<ReorderableListItem
								key={item.id}
								id={item.id}
								index={index}
								item={item}
								url={itemBaseURL && `${itemBaseURL}${item.id}/`}
							/>
						))
					) : (
						<li className="px-4 py-4 text-sm">
							Add an item using the buttons below
						</li>
					)}
				</SortableContext>
			</DndContext>
		</ul>
	);
}

export function ReorderableListItem({ id, item, index, url = null }) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<li
			ref={setNodeRef}
			style={style}
			className="relative flex items-center hover:bg-gray-100 cursor-pointer"
		>
			<div
				className="pl-6 py-5 pr-4 cursor-grab"
				{...attributes}
				{...listeners}
			>
				<GripIcon className="w-4 h-4 text-gray-600" />
			</div>

			<Link
				to={url}
				className="flex w-full items-center justify-between gap-x-4 pr-6 py-5"
			>
				<div className="min-w-0 flex-auto">
					<h2 className="text-sm font-normal leading-6 text-gray-900">
						{item.name}
					</h2>
				</div>
				<div
					to={url}
					className="flex shrink-0 items-center gap-x-4 ml-auto"
				>
					<ChevronRightIcon
						aria-hidden="true"
						className="h-5 w-5 flex-none text-gray-400"
					/>
				</div>
			</Link>
		</li>
	);
}
