import { createPortal } from "react-dom";
import { cloneElement, createContext, useContext, useState } from "react";

import style from "./Modal.module.css";

import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

const ModalContext = createContext();

function Modal({ children }) {
	const [openName, setOpenName] = useState("");
	const close = () => setOpenName("");
	const open = setOpenName;

	return (
		<ModalContext.Provider value={{ openName, close, open }}>
			{children}
		</ModalContext.Provider>
	);
}

function Open({ children, opens: opensWindowName }) {
	const { open } = useContext(ModalContext);
	return cloneElement(children, { onClick: () => open(opensWindowName) });
}

function Window({ children, name }) {
	const { openName, close } = useContext(ModalContext);
	if (name !== openName) return null;
	return createPortal(
		<div
			className={style.overlay}
			onClick={(e) => {
				e.target === e.currentTarget ? close() : null;
			}}
		>
			<div className={style.modal}>
				<Button className={style.btn} onClick={close}>
					<Icon icon="fa-times" />
				</Button>
				<div>{cloneElement(children, { onCloseModal: close })}</div>
			</div>
		</div>,
		document.body
	);
}

Modal.Open = Open;
Modal.Window = Window;

export default Modal;
