import style from "./SettingsSection.module.css";

function SettingSection({ title, description, children }) {
	return (
		<section className={style.section}>
			<div className={style.side}>
				<h3>{title}</h3>
				{description && <p>{description}</p>}
			</div>
			<div className={style.main}>{children}</div>
		</section>
	);
}

export default SettingSection;
