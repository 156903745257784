import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import BackButton from "@/features/admin/components/ui/BackButton";
import { useGetUnit } from "../../hooks/useGetUnit";
import UnitInfo from "../../components/courses/UnitInfo";
import UnitActions from "../../components/courses/UnitActions";
import VideoUnitUpload from "../../components/courses/VideoUnitUpload";

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/features/admin/components/ui/Tabs";
import VideoUnitInfo from "../../components/courses/VideoUnitInfo";
import VideoUnitVideoTab from "../../components/courses/VideoUnitVideoTab";
import QuizQuestions from "../../components/courses/QuizQuestions";

export default function ViewUnit({ tab }) {
	let { courseID, moduleID, unitID } = useParams();

	const { unit, isSuccess, isPending, isError, error } = useGetUnit({
		courseID,
		moduleID,
		unitID,
	});

	const navigate = useNavigate();

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton to="../../" />{" "}
					{isSuccess ? unit.name : "View Unit"}
				</span>
			</PageTitle>

			{isSuccess && (
				<Tabs
					defaultValue={tab}
					onValueChange={(value) => navigate(`../${value}/`)}
				>
					<TabsList className="mb-1">
						<TabsTrigger value="details">General</TabsTrigger>

						{unit.unit_type == "video" && (
							<TabsTrigger value="video-details">
								Video Specific
							</TabsTrigger>
						)}
						{unit.unit_type == "quiz" && (
							<TabsTrigger value="quiz-questions">
								Questions
							</TabsTrigger>
						)}
					</TabsList>
					<TabsContent value="details">
						<div className="grid lg:grid-cols-3 gap-4">
							<div className="lg:col-span-2">
								<UnitInfo
									courseID={courseID}
									moduleID={moduleID}
									unit={unit}
								/>
							</div>
							<div>
								<UnitActions
									courseID={courseID}
									moduleID={moduleID}
									unit={unit}
								/>
							</div>
						</div>
					</TabsContent>

					{unit.unit_type == "video" && (
						<TabsContent value="video-details">
							<VideoUnitVideoTab unit={unit} />
						</TabsContent>
					)}

					{unit.unit_type == "quiz" && (
						<TabsContent value="quiz-questions">
							<QuizQuestions
								courseID={courseID}
								moduleID={moduleID}
								unit={unit}
							/>
						</TabsContent>
					)}
				</Tabs>
			)}
			{isPending && <Loader />}
			{isError && <QueryError error={error} />}
		</>
	);
}
