import { checkVerificationCode } from "../api";
import { useApiSend } from "@/hooks/useApi";

export function useCheckVerificationCode() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: (data) =>
			checkVerificationCode({ email: data.email, code: data.code }),
	});

	return { checkCode: mutateAsync, isPending, error, isError };
}
