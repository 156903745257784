import style from "./Icon.module.css";

function SoundWaveIco() {
	return (
		<div className={style.soundWaves}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}

function CurrentModuleIcon({ completion }) {
	return (
		<div className={style.currentIco}>
			<div className={style.percentage}>{completion}%</div>
			<div className={style.progress}>
				<div
					className={style.fill}
					style={{ width: `${completion}%` }}
				></div>
			</div>
		</div>
	);
}

export { SoundWaveIco, CurrentModuleIcon };
