import { useApiGet } from "@/hooks/useApi";
import { getCourseUnits } from "../api";

export function useGetCourseUnits({ courseSlug }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", courseSlug, "units", "list"],
		fn: () => getCourseUnits({ courseSlug }),
	});

	return { ...data, isPending, isSuccess, isError, error };
}
