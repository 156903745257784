import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { useGetUsers } from "@/features/admin/hooks/useGetUsers";
import UsersTable from "@/features/admin/components/users/UsersTable";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import {
	Breadcrumbs,
	usersBreadcrumbs,
} from "@/features/admin/components/Breadcrumbs";

function Users() {
	const { users, isSuccess, isPending, isError, error } = useGetUsers();

	// <Breadcrumbs items={usersBreadcrumbs()}/>

	return (
		<>
			<PageTitle>Users </PageTitle>
			{isSuccess && <UsersTable users={users} />}
			{isPending && <Loader />}
			{isError && <QueryError error={error} />}
		</>
	);
}

export default Users;
