import SidebarLink from "./SidebarLink";
import { Home, Users, MonitorPlay } from "lucide-react";

function SidebarLinks({ variant, closeSidebar }) {
	return (
		<>
			<SidebarLink
				url="/admin/dashboard/"
				name="Dashboard"
				variant={variant}
				closeSidebar={closeSidebar}
				Icon={Home}
			/>
			<SidebarLink
				url="/admin/users/"
				name="Users"
				variant={variant}
				closeSidebar={closeSidebar}
				Icon={Users}
			/>
			<SidebarLink
				url="/admin/courses/"
				name="Courses"
				variant={variant}
				closeSidebar={closeSidebar}
				Icon={MonitorPlay}
			/>
		</>
	);
}

export default SidebarLinks;
