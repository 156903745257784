import { useApiGet } from "@/hooks/useApi";
import { getIncompleteCourse } from "../api";

export function useGetIncompleteCourse() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", "incomplete-course"],
		fn: getIncompleteCourse,
	});

	return { data, isPending, isSuccess, isError, error };
}
