import { Outlet } from "react-router-dom";
import Sidebar from "@/ui/sidebar/Sidebar";
import Container from "@/layouts/container/Container";
import Header from "@/ui/header/Header";

function AppLayout() {
	return (
		<>
			<Header />
			<Outlet />
		</>
	);
}

export default AppLayout;
