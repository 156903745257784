import style from "./ReviewCard.module.css";

import Icon from "@/ui/icon/Icon";

function ReviewCard({ imgUrl, name, date, rating, text }) {
	return (
		<div className={style.review}>
			<div className={style.header}>
				<div className={style.user}>
					<div className={style.imgHolder}>
						<img src={imgUrl} alt={`${name}'s avatar`} />
					</div>
					<div className={style.userInfo}>
						<div className={style.name}>{name}</div>
						<div className={style.date}>{date}</div>
					</div>
				</div>
				<div className={style.more}>
					<Icon icon="fa-ellipsis-v" />
				</div>
			</div>
			<div className={style.body}>
				<div className={style.starsHolder}>
					<div
						className={style.stars}
						style={{ "--rating": rating }}
					></div>
					<div className={style.reviews}>
						Ocijenio/la sa ocjenom <span>{rating}</span>
					</div>
				</div>
				<div className={style.text}>{text}</div>
			</div>
		</div>
	);
}

export default ReviewCard;
