import { useEffect } from "react";
import { Card, CardTitle } from "../../ui/Card";
import { Link } from "react-router-dom";

export default function CourseCard({ row }) {
	return (
		<Link
			to={`/admin/courses/${row.getValue("id")}/`}
			className="shadow-sm border hover:border-gray-500 rounded-lg overflow-hidden"
		>
			<img
				className="aspect-[16/9] object-cover w-full"
				src={row.renderValue("thumbnail")}
			/>
			<div className="p-4">
				<h2 className="text-base font-normal">
					{row.renderValue("name")}
				</h2>
				<p className="text-sm text-gray-600 capitalize">
					{row.renderValue("categories")}
				</p>
			</div>
		</Link>
	);
}
