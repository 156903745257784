import { useApiSend } from "@/hooks/useApi";
import { unsaveCourse } from "../api";

export function useUnsaveCourse({ course }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["courses", course.slug, "save"],
		invalidateKeys: [["courses", "list"]],
		fn: () => unsaveCourse({ course }),
	});

	return { unsaveCourse: mutateAsync, isPending, error, isError };
}
