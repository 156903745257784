import { useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import InputError from "../ui/InputError";
import { useState } from "react";
import { XIcon } from "lucide-react";
import { useRef } from "react";
import { Button } from "../ui/Button";
import { Controller } from "react-hook-form";

export default function FormImage({
	name,
	label,
	initialPreviewImageURL = null,
	...props
}) {
	const {
		control,
		setValue,
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	const imageInputRef = useRef();
	const [previewImageURL, setPreviewImageURL] = useState(
		initialPreviewImageURL
	);
	const [clearImage, setClearImage] = useState(false);

	if (!label) label = capitalize(name);

	const handleImageChange = function (e) {
		if (e.target.files && e.target.files[0]) {
			setValue(`${name}_clear`, false);
			setClearImage(false);
			let reader = new FileReader();
			reader.onload = function (ev) {
				setPreviewImageURL(ev.target.result);
			}.bind(this);
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const handleImageRemove = function () {
		imageInputRef.current.value = null;
		setValue(`${name}_clear`, true);
		setClearImage(true);
		setPreviewImageURL(null);
	};

	register(`${name}_clear`);

	return (
		<div>
			<Label htmlFor={name}>{label}</Label>
			<Controller
				control={control}
				name={name}
				render={({ field }) => {
					return (
						<>
							{previewImageURL && (
								<div className="mt-1.5">
									<div className="group inline-block relative rounded-lg">
										<img
											src={previewImageURL}
											className="rounded-lg max-h-36"
										/>
										<div
											onClick={handleImageRemove}
											className="absolute cursor-pointer inset-0 hidden group-hover:flex items-center justify-center p-4 bg-black bg-opacity-50 rounded-lg"
										>
											<XIcon className="w-6 h-6 text-white" />
										</div>
									</div>
								</div>
							)}

							<Input
								type="file"
								className="mt-1.5 cursor-pointer"
								id={name}
								ref={imageInputRef}
								onChange={(e) => {
									field.onChange(e.target.files[0]);
									handleImageChange(e);
								}}
								{...props}
							/>
						</>
					);
				}}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}
