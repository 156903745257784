import React from "react";
import style from "./FinishedScreen.module.css";

import Icon from "@/ui/icon/Icon";
import Container from "@/layouts/container/Container";
import { Link } from "react-router-dom";

function FinishedScreen({ points, maxPoints }) {
	const percentage = Math.floor((points / maxPoints) * 100);

	const message =
		percentage >= 90
			? "Odlično!"
			: percentage >= 70
			? "Vrlo dobro!"
			: percentage >= 50
			? "Dobro!"
			: percentage >= 30
			? "Može bolje!"
			: "Pokušaj ponovo!";

	return (
		<Container size="medium">
			<div className={style.finishedHolder}>
				<Link to="/" className={style.goBack}>
					<Icon icon="fa-arrow-left" />
				</Link>
				<div className={style.quizResults}>
					<h2>Rezultati kviza:</h2>
					<div className={style.statsHolder}>
						<div className={style.statPresenter}>
							<div className={style.header}>
								<span>{message}</span>
							</div>
							<div className={style.body}>
								<Icon icon="fa-bullseye-arrow" />
								<span>{percentage}%</span>
							</div>
						</div>
						<div className={style.statPresenter}>
							<div className={style.header}>
								<span>Bodovi</span>
							</div>
							<div className={style.body}>
								<Icon icon="fa-bolt" />
								<span>{points}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default FinishedScreen;
