import style from "./Label.module.css";

function Label({ children, required, ...props }) {
	return (
		<label className={style.label} {...props}>
			{children}
			{required && <span className={style.required}>*</span>}
		</label>
	);
}

export default Label;
