import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Link, NavLink, useParams } from "react-router-dom";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import BackButton from "@/features/admin/components/ui/BackButton";
import { useGetCourse } from "../../hooks/useGetCourse";
import EditCourseForm from "../../components/courses/EditCourseForm";

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/features/admin/components/ui/Tabs";
import { useGetCategories } from "../../hooks/useGetCategories";
import { useGetLecturers } from "../../hooks/useGetLecturers";

export default function EditCourse() {
	let { courseID } = useParams();

	const courseQuery = useGetCourse({
		courseID,
	});

	const categoryQuery = useGetCategories();
	const lecturerQuery = useGetLecturers();

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton to={`/admin/courses/${courseID}/`} />{" "}
					{courseQuery.isSuccess
						? `Edit ${courseQuery.course.name}`
						: "Edit Course"}
				</span>
			</PageTitle>

			{courseQuery.isSuccess &&
				categoryQuery.isSuccess &&
				lecturerQuery.isSuccess && (
					<EditCourseForm
						course={courseQuery.course}
						availableCategories={categoryQuery.categories}
						availableLecturers={lecturerQuery.lecturers}
					/>
				)}

			{(courseQuery.isPending ||
				categoryQuery.isPending ||
				lecturerQuery.isPending) && <Loader />}

			{courseQuery.isError && <QueryError error={courseQuery.error} />}
			{categoryQuery.isError && (
				<QueryError error={categoryQuery.error} />
			)}
			{lecturerQuery.isError && (
				<QueryError error={lecturerQuery.error} />
			)}
		</>
	);
}
