import { useState } from "react";
import style from "./LeaveReviewForm.module.css";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

function LeaveReviewForm({ onCloseModal }) {
	const [rating, setRating] = useState(0);
	const [text, setText] = useState("");
	const [hoverRating, setHoverRating] = useState(0);

	const handleSubmit = (e) => {
		e.preventDefault();
		setRating(0);
		setText("");
		onCloseModal();
	};

	const handleRating = (newRating) => {
		setRating(newRating);
	};

	return (
		<form className={style.reviewForm} onSubmit={handleSubmit}>
			<div className={style.header}>
				<h3>[TITLE OF THE COURSE]</h3>
				<p>Molim vas izdvojite momenat da ostavite recenziju</p>
			</div>
			<div className={style.body}>
				<div className={style.starsHolder}>
					{[1, 2, 3, 4, 5].map((star) => (
						<span
							key={star}
							className={`${style.star} ${
								star <= (hoverRating || rating)
									? style.filled
									: ""
							}`}
							onClick={() => handleRating(star)}
							onMouseEnter={() => setHoverRating(star)}
							onMouseLeave={() => setHoverRating(0)}
						>
							<Icon icon="fa-star" />
						</span>
					))}
				</div>
				<span className={style.ratingDisplay}>{rating}</span>
				<textarea
					id="review"
					value={text}
					onChange={(e) => setText(e.target.value)}
					placeholder="Vaša recenzija..."
				></textarea>
			</div>
			<div className={style.footer}>
				<Button type="accent" className={style.btn} disabled={!rating}>
					Submit
				</Button>
			</div>
		</form>
	);
}

export default LeaveReviewForm;
