import { editCourse } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useEditCourse({ courseID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["admin", "courses", courseID, "edit"],
		fn: (data) =>
			editCourse({ courseID: courseID, updatedCourseData: data }),
		invalidateKeys: [["admin", "courses"]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
