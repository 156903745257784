import { Card, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import ConfirmationModal from "../ConfirmationModal";
import { useDeleteModule } from "../../hooks/useDeleteModule";
import { useNavigate } from "react-router-dom";
import { successToast } from "@/ui/toast/Toast";
import { useDeleteUnit } from "../../hooks/useDeleteUnit";

export default function UnitActions({ courseID, moduleID, unit }) {
	const { deleteUnit } = useDeleteUnit({
		courseID: courseID,
		moduleID: moduleID,
		unitID: unit.id,
	});

	const navigate = useNavigate();

	const deleteUnitAction = async function () {
		try {
			await deleteUnit();
			navigate(`/admin/courses/${courseID}/modules/${moduleID}/units/`);
			successToast(`Successfully deleted the unit ${unit.name}`);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Card>
			<CardContent className="p-6">
				<div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-2">
					<ConfirmationModal
						action={deleteUnitAction}
						buttonText="Delete"
					>
						<Button variant="outline">Delete Unit</Button>
					</ConfirmationModal>
				</div>
			</CardContent>
		</Card>
	);
}
