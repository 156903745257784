"use client";

import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
} from "@tanstack/react-table";

import { Input } from "@/features/admin/components/ui/Input";
import { useState } from "react";

import TablePagination from "@/features/admin/components/TablePagination";
import { useNavigate } from "react-router-dom";
import CourseCard from "./CourseCard";
import { createColumnHelper } from "@tanstack/react-table";
import { Button } from "@/features/admin/components//ui/Button";
import { PackagePlus } from "lucide-react";
import CreateCourseModal from "../CreateCourseModal";

const columnHelper = createColumnHelper();

export const columns = [
	columnHelper.accessor("id"),
	columnHelper.accessor("name"),
	columnHelper.accessor("thumbnail"),
	columnHelper.accessor(
		(row) => {
			if (row.categories.length > 0)
				return row.categories
					.map((category) => category.name)
					.join(", ");
			return "Nekategorisano";
		},
		{
			id: "categories",
		}
	),
];

export default function CoursesTable({ courses }) {
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	const table = useReactTable({
		data: courses,
		columns: columns,
		state: {
			globalFilter: searchQuery,
		},
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 8,
			},
		},
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onGlobalFilterChange: setSearchQuery,
	});

	const [isCreateCourseModalOpen, setIsCreateCourseModalOpen] =
		useState(false);

	return (
		<div className="space-y-4">
			<div className="w-full flex items-center gap-2">
				<div className="w-full max-w-sm">
					<Input
						placeholder="Search courses"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</div>
				<Button
					className="ml-auto"
					onClick={() => setIsCreateCourseModalOpen(true)}
				>
					New Course
				</Button>
			</div>

			<div className="w-full grid sm:grid-cols-3 gap-6">
				{table.getRowModel().rows?.length
					? table
							.getRowModel()
							.rows.map((row) => (
								<CourseCard key={row.id} row={row} />
							))
					: ""}
				<CreateCourseButton
					onClick={() => setIsCreateCourseModalOpen(true)}
				/>
			</div>

			<TablePagination table={table} />

			<CreateCourseModal
				isOpen={isCreateCourseModalOpen}
				onOpenChange={setIsCreateCourseModalOpen}
			/>
		</div>
	);
}

function CreateCourseButton({ onClick }) {
	return (
		<button
			type="button"
			className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-12 py-20 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
			onClick={onClick}
		>
			<PackagePlus
				className="mx-auto h-12 w-12 text-gray-400"
				strokeWidth={1}
			/>
			<span className="mt-2 block text-sm font-semibold text-gray-700">
				Add a Course
			</span>
		</button>
	);
}
