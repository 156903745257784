import { Card, CardHeader, CardContent } from "../ui/Card";
import { Button } from "../ui/Button";
import { Link } from "react-router-dom";
import { CourseStatus } from "./CourseStatus";
import { toLocalDate } from "../../lib/utils";

export default function CourseInfo({ course }) {
	return (
		<Card>
			<CardHeader className="px-4 py-6 sm:px-6 flex gap-2">
				<div className="space-y-0">
					<h2 className="text-base font-semibold text-gray-900">
						Course details
					</h2>
					<p className="max-w-2xl text-sm leading-6 text-gray-500">
						Course information & status
					</p>
				</div>
				<div className="ml-auto">
					<Link to={`/admin/courses/${course.id}/edit/`}>
						<Button variant="outline">Edit</Button>
					</Link>
				</div>
			</CardHeader>
			<CardContent className="border-t border-gray-200 p-0">
				<dl className="divide-y divide-gray-200">
					<CourseInfoRow
						label="Status"
						value={<CourseStatus status={course.status} />}
					/>
					<CourseInfoRow label="Course Name" value={course.name} />
					<CourseInfoRow label="Slug" value={course.slug} />
					<CourseInfoRow
						label="Date Published"
						value={toLocalDate(course.date_published)}
					/>
					<CourseInfoRow
						label="Categories"
						value={
							<span className="capitalize">
								{course.categories?.length
									? course.categories
											.map((category) => category.name)
											.join(", ")
									: "No categories"}
							</span>
						}
					/>
					<CourseInfoRow
						label="Lecturers"
						value={
							<span className="capitalize">
								{course.lecturers?.length
									? course.lecturers
											.map((lecturer) => lecturer.name)
											.join(", ")
									: "No lecturers"}
							</span>
						}
					/>
					<CourseInfoRow
						label="Thumbnail"
						value={
							course.thumbnail ? (
								<img
									src={course.thumbnail}
									className="aspect-[16/9] object-cover rounded-lg max-w-72"
								/>
							) : (
								"No thumbnail"
							)
						}
					/>
					<CourseDescriptionRow
						label="Description"
						value={course.description}
						allowHTML={true}
					/>
				</dl>
			</CardContent>
		</Card>
	);
}

function CourseDescriptionRow({ label, value }) {
	return (
		<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
			<dt className="text-sm font-medium text-gray-900 flex items-center">
				{label}
			</dt>
			<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
				<div
					className="prose prose-sm"
					dangerouslySetInnerHTML={{ __html: value }}
				/>
			</dd>
		</div>
	);
}

function CourseInfoRow({ label, value }) {
	return (
		<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
			<dt className="text-sm font-medium text-gray-900 flex items-center">
				{label}
			</dt>
			<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
				{value}
			</dd>
		</div>
	);
}
