import React from "react";
import { PageTitle } from "@/features/admin/components/PageTitle";
import { Link, NavLink, useParams } from "react-router-dom";
import Loader from "@/features/admin/components/Loader";
import QueryError from "@/features/admin/components/QueryError";
import BackButton from "@/features/admin/components/ui/BackButton";
import EditUnitForm from "../../components/courses/EditUnitForm";

import { useGetUnit } from "../../hooks/useGetUnit";

export default function EditUnit() {
	let { courseID, moduleID, unitID } = useParams();

	const { isSuccess, unit, isPending, isError, error } = useGetUnit({
		courseID,
		moduleID,
		unitID,
	});

	return (
		<>
			<PageTitle>
				<span className="flex items-center gap-1.5">
					<BackButton to=".." />{" "}
					{isSuccess ? `Edit ${unit.name}` : "Edit Unit"}
				</span>
			</PageTitle>

			{isSuccess && (
				<EditUnitForm
					courseID={courseID}
					moduleID={moduleID}
					unit={unit}
				/>
			)}
			{isPending && <Loader />}

			{isError && <QueryError error={error} />}
		</>
	);
}
