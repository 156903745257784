import { request } from "@/services/network";

export async function getSubscriptionDetails() {
	return request({
		url: "/payments/subscription-details/",
		method: "GET",
	});
}

export async function createSubscriptionOrder() {
	return request({
		url: "/payments/create-subscription-order/",
		method: "POST",
	});
}
