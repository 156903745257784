import { useFormContext, useController } from "react-hook-form";
import Label from "./Label";
import InputError from "./InputError";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import style from "./InputPhone.module.css";

// see this https://github.com/goveo/react-international-phone

function InputPhone({ name, required, label, ...props }) {
	const {
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	const {
		field,
		fieldState: { error },
	} = useController({ name });

	return (
		<div className={style.holder}>
			{label && (
				<Label required={required} htmlFor={name}>
					{label}
				</Label>
			)}

			<PhoneInput
				className={style.input}
				defaultCountry="ba"
				id={name}
				value={field.value}
				onChange={field.onChange}
				required={required}
				disabled={isSubmitting}
			/>

			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

export default InputPhone;
