import { Badge } from "../ui/Badge";

export function CourseStatus({ status }) {
	return (
		<Badge
			size="md"
			variant={status == "published" ? "green" : "secondary"}
		>
			<span className="capitalize">{status}</span>
		</Badge>
	);
}
