import { useGetUnitComments } from "../../hooks/useGetUnitComments";

import PostCommentForm from "../postCommentForm/PostCommentForm";
import ParentComment from "../parentComment/ParentComment";

import style from "./CommentSection.module.css";

function CommentSection({ video }) {
	const { comments, isSuccess, isPending, isError, error } =
		useGetUnitComments({ unit: video });

	if (isPending) return <>Učitavanje...</>;
	if (isError) return <>Error: {JSON.stringify(error)}</>;

	return (
		<div className={style.commentSection}>
			<PostCommentForm video={video} />
			<ul className={style.commentsHolder}>
				{comments.map((comment) => (
					<ParentComment
						unit={video}
						comment={comment}
						key={comment.uuid}
					/>
				))}
			</ul>
		</div>
	);
}

export default CommentSection;
