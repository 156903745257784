import { getUnits } from "../api/courses";
import { useApiGet } from "@/hooks/useApi";

export function useGetUnits({ courseID, moduleID }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"units",
			"list",
		],
		fn: () => getUnits({ courseID, moduleID }),
	});

	const { units } = data || { units: [] };

	return { units, isPending, isSuccess, isError, error };
}
