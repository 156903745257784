import style from "./DropdownMenu.module.css";
import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Check, ChevronRight, Circle } from "lucide-react";

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuGroup = DropdownMenuPrimitive.Group;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

const DropdownMenuSubTrigger = React.forwardRef(
	({ className, inset, children, ...props }, ref) => (
		<DropdownMenuPrimitive.SubTrigger
			ref={ref}
			className={`${style.subTrigger} ${
				inset ? style.inset : ""
			} ${className}`}
			{...props}
		>
			{children}
			<ChevronRight className={style.chevronRight} />
		</DropdownMenuPrimitive.SubTrigger>
	)
);
DropdownMenuSubTrigger.displayName =
	DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = React.forwardRef(
	({ className, ...props }, ref) => (
		<DropdownMenuPrimitive.SubContent
			ref={ref}
			className={`${style.subContent} ${className}`}
			{...props}
		/>
	)
);
DropdownMenuSubContent.displayName =
	DropdownMenuPrimitive.SubContent.displayName;

const DropdownMenuContent = React.forwardRef(
	({ className, sideOffset = 4, ...props }, ref) => (
		<DropdownMenuPrimitive.Portal>
			<DropdownMenuPrimitive.Content
				ref={ref}
				sideOffset={sideOffset}
				className={`${style.content} ${className}`}
				{...props}
			/>
		</DropdownMenuPrimitive.Portal>
	)
);
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef(
	({ className, inset, ...props }, ref) => (
		<DropdownMenuPrimitive.Item
			ref={ref}
			className={`${style.item} ${inset ? style.inset : ""} ${className}`}
			{...props}
		/>
	)
);
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuCheckboxItem = React.forwardRef(
	({ className, children, checked, ...props }, ref) => (
		<DropdownMenuPrimitive.CheckboxItem
			ref={ref}
			className={`${style.checkboxItem} ${className}`}
			checked={checked}
			{...props}
		>
			<span className={style.checkboxIndicator}>
				<DropdownMenuPrimitive.ItemIndicator>
					<Check className={style.checkIcon} />
				</DropdownMenuPrimitive.ItemIndicator>
			</span>
			{children}
		</DropdownMenuPrimitive.CheckboxItem>
	)
);
DropdownMenuCheckboxItem.displayName =
	DropdownMenuPrimitive.CheckboxItem.displayName;

const DropdownMenuRadioItem = React.forwardRef(
	({ className, children, ...props }, ref) => (
		<DropdownMenuPrimitive.RadioItem
			ref={ref}
			className={`${style.radioItem} ${className}`}
			{...props}
		>
			<span className={style.radioIndicator}>
				<DropdownMenuPrimitive.ItemIndicator>
					<Circle className={style.circleIcon} />
				</DropdownMenuPrimitive.ItemIndicator>
			</span>
			{children}
		</DropdownMenuPrimitive.RadioItem>
	)
);
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

const DropdownMenuLabel = React.forwardRef(
	({ className, inset, ...props }, ref) => (
		<DropdownMenuPrimitive.Label
			ref={ref}
			className={`${style.label} ${
				inset ? style.inset : ""
			} ${className}`}
			{...props}
		/>
	)
);
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef(
	({ className, ...props }, ref) => (
		<DropdownMenuPrimitive.Separator
			ref={ref}
			className={`${style.separator} ${className}`}
			{...props}
		/>
	)
);
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenuShortcut = ({ className, ...props }) => {
	return <span className={`${style.shortcut} ${className}`} {...props} />;
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

export {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuCheckboxItem,
	DropdownMenuRadioItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuGroup,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuRadioGroup,
};
