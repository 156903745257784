import { getModules } from "../api/courses";
import { useApiGet } from "@/hooks/useApi";

export function useGetModules({ courseID }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin", "courses", courseID, "modules", "list"],
		fn: () => getModules({ courseID }),
	});

	const { modules } = data || { modules: [] };

	return { modules, isPending, isSuccess, isError, error };
}
