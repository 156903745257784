import React from "react";
import Options from "../options/Options";

import style from "./Question.module.css";

function Question({ question, dispatch, answer, hasAnswered }) {
	return (
		<div className={style.questionHolder}>
			<h4>{question.question}</h4>
			<Options
				hasAnswered={hasAnswered}
				dispatch={dispatch}
				answer={answer}
				question={question}
			/>
		</div>
	);
}

export default Question;
