import { useApiGet } from "@/hooks/useApi";
import { getSubscriptionDetails } from "../api";

export function useSubscriptionDetails() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["whoami", "subscription-details"],
		fn: () => getSubscriptionDetails(),
	});

	return { subscription: data, isPending, isSuccess, isError, error };
}
