import { getUser, getUsers } from "@/features/admin/api/users";
import { useApiGet } from "@/hooks/useApi";

export function useGetUser({ userUUID }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: [`admin_getUser_${userUUID}`],
		fn: () => getUser(userUUID),
	});

	const { user } = data || { user: [] };

	return { user, isPending, isSuccess, isError, error };
}
