import { editUnit } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useEditUnit({ courseID, moduleID, unitID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: [
			"admin",
			"courses",
			courseID,
			"modules",
			moduleID,
			"units",
			unitID,
			"edit",
		],
		fn: (data) =>
			editUnit({
				courseID: courseID,
				moduleID: moduleID,
				unitID: unitID,
				updatedUnitData: data,
			}),
		invalidateKeys: [
			[
				"admin",
				"courses",
				courseID,
				"modules",
				moduleID,
				"units",
				unitID,
			],
		],
	});

	return { save: mutateAsync, isPending, error, isError };
}
