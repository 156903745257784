import { Outlet } from "react-router-dom";
import style from "./PublicPages.module.css";

function PublicPages() {
	return (
		<div className={style.root}>
			<Outlet />
		</div>
	);
}

export default PublicPages;
