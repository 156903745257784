import { useApiGet } from "@/hooks/useApi";
import { getLecturers } from "@/features/admin/api/courses";

export function useGetLecturers() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin", "lecturers"],
		fn: getLecturers,
	});

	const { lecturers } = data || { lecturers: [] };

	return { lecturers, isPending, isSuccess, isError, error };
}
