import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";

import "./features/admin/index.css";
import "./css/main.css";

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
