import Logo from "../logo/Logo";
import style from "./FullPageLoader.module.css";

function FullPageLoader() {
	return (
		<div className={style.page}>
			<div className={style.header}>
				<span className={style.loader}></span>
			</div>
			<div className={style.body}>
				<div className={style.logo}>
					<Logo />
				</div>
				<span className={style.loader}></span>
			</div>
		</div>
	);
}

export default FullPageLoader;
