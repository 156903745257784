import { Link } from "react-router-dom";

import logoImg from "@/assets/images/logo.svg";
import miniLogoImg from "@/assets/images/logo-mini.svg";

import style from "./Logo.module.css";

function Logo() {
	return (
		<Link className={style.logo} to="/">
			<img className={style.fullLogo} src={logoImg}></img>
			<img className={style.miniLogo} src={miniLogoImg}></img>
		</Link>
	);
}

export default Logo;
