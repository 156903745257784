import { createModule } from "../api/courses";
import { useApiSend } from "@/hooks/useApi";

export function useCreateModule({ courseID }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["admin", "courses", courseID, "modules", "create"],
		fn: (data) => createModule({ courseID, moduleData: data }),
		invalidateKeys: [["admin", "courses", courseID, "modules"]],
	});

	return { save: mutateAsync, isPending, error, isError };
}
