import Icon from "../icon/Icon";
import style from "./SearchInput.module.css";

function SearchInput() {
	return (
		<div className={style.searchHolder}>
			<div className={style.icoHolder}>
				<Icon icon="fa-magnifying-glass" />
			</div>
			<input type="text" placeholder="Pretraži..."></input>
		</div>
	);
}

export default SearchInput;
