import { useState } from "react";
import style from "./CourseCard.module.css";
import { Link } from "react-router-dom";
import { secondsToHoursMinutes } from "@/utils/helpers";
import CourseCategory from "../courseCategory/CourseCategory";
import Icon from "@/ui/icon/Icon";
import { successToast } from "@/ui/toast/Toast";
import { useSaveCourse } from "../../hooks/useSaveCourse";
import { useUnsaveCourse } from "../../hooks/useUnsaveCourse";

function CourseCard({ course }) {
	const {
		thumbnail,
		slug,
		name,
		like_count: likeCount,
		module_count: moduleCount,
		duration,
	} = course;

	const { saveCourse } = useSaveCourse({ course });
	const { unsaveCourse } = useUnsaveCourse({ course });

	const handleAddToWatchListClick = async (e) => {
		e.preventDefault();
		try {
			if (course?.is_saved) {
				await unsaveCourse();
				successToast("Kurs uklonjen iz Mojih kurseva");
			} else {
				await saveCourse();
				successToast("Kurs dodan u Moji kursevi");
			}
		} catch (e) {
			console.log("Save course error", e);
		}
	};

	return (
		<div className={style.course}>
			<Link
				to={`/courses/${slug}`}
				className={style.thumbnail}
				style={{
					"--image": `url(${thumbnail})`,
				}}
			>
				<CourseCategory course={course} className={style.category} />
				<div
					className={`${style.addToWatchlist} ${
						course?.is_saved ? style.active : ""
					}`}
					onClick={handleAddToWatchListClick}
				>
					<Icon icon="fa-bookmark" />
				</div>
			</Link>
			<div className={style.info}>
				<div className={style.header}>
					<Link to={`/courses/${slug}`} className={style.title}>
						{name}
					</Link>
					{course?.lecturers?.length > 0 && (
						<div className={style.author}>
							<img
								src={course.lecturers[0]?.picture_url}
								alt={course.lecturers[0]?.name}
							/>
							<div className={style.name}>
								{course.lecturers[0]?.name}
							</div>
						</div>
					)}
				</div>
				<div className={style.footer}>
					<div className={style.otherInfo}>
						<div>
							<Icon icon="fa-heart" />
							<span>{likeCount}</span>
						</div>
						<span className={style.sep}></span>
						<div>
							<Icon icon="fa-layer-group" />
							<span>{moduleCount}</span>
						</div>
						<span className={style.sep}></span>
						<div>
							<Icon icon="fa-timer" />
							<span>{secondsToHoursMinutes(duration)}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CourseCard;
