import React from "react";
import style from "./RegisterSidebar.module.css";

import { Link } from "react-router-dom";

import LogoMini from "@/assets/images/logo-mini.svg";

import Icon from "@/ui/icon/Icon";

import Logo from "@/ui/logo/Logo";

function RegisterSidebar({ stepIndex }) {
	const steps = [
		{
			name: "Unesite email",
			icon: "fa-at",
		},
		{
			name: "Potvrdite email",
			icon: "fa-envelopes",
		},
		{
			name: "Unesite informacije",
			icon: "fa-user",
		},
		{
			name: "Odaberite plan",
			icon: "fa-tasks",
		},
	];

	return (
		<div className={style.sidebar}>
			<div className={style.header}>
				<Logo />
			</div>
			<div className={style.body}>
				<div className={style.steps}>
					{steps.map((step, index) => (
						<div
							key={index + 1}
							className={`${style.step} ${
								stepIndex == index + 1 ? style.active : ""
							} ${index + 1 < stepIndex ? style.done : ""}`}
						>
							<div className={style.icoHolder}>
								<Icon icon={step.icon} />
								{index + 1 < stepIndex && (
									<div className={style.check}>
										<Icon icon="fa-check" />
									</div>
								)}
							</div>
							<div className={style.info}>
								<span className={style.name}>{step.name}</span>
								<span className={style.description}>
									Korak {index + 1}
								</span>
							</div>
						</div>
					))}
				</div>
				<img
					className={style.logoBackground}
					src={LogoMini}
					alt="mini-logo"
				/>
			</div>

			<div className={style.footer}>
				<div className={style.back}>
					<Icon icon="fa-arrow-left" />
					<Link to="/">Povratak na početnu</Link>
				</div>
				<div className={style.link}>
					<Link to="/login"> Prijavite se</Link>
				</div>
			</div>
		</div>
	);
}

export default RegisterSidebar;
