import { Button } from "./ui/Button";
import { ChevronLeft, ChevronRight } from "lucide-react";

function TablePagination({ table }) {
	const currentPageInfo = {
		from: Math.min(
			table.getState().pagination.pageIndex *
				table.getState().pagination.pageSize +
				1,
			table.getRowCount()
		),
		to: Math.min(
			(table.getState().pagination.pageIndex + 1) *
				table.getState().pagination.pageSize,
			table.getRowCount()
		),
	};

	return (
		<div className="flex items-center justify-end gap-2">
			<span className="text-sm text-gray-600 pr-2">
				{currentPageInfo.from} - {currentPageInfo.to} of{" "}
				{table.getRowCount()}
			</span>
			<Button
				variant="outline"
				onClick={() => table.previousPage()}
				disabled={!table.getCanPreviousPage()}
			>
				Previous
			</Button>
			<Button
				variant="outline"
				onClick={() => table.nextPage()}
				disabled={!table.getCanNextPage()}
			>
				Next
			</Button>
		</div>
	);
}

export default TablePagination;
