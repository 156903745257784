import { getCourse } from "../api/courses";
import { useApiGet } from "@/hooks/useApi";

export function useGetCourse({ courseID }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["admin", "courses", courseID],
		fn: () => getCourse({ courseID }),
	});

	const { course } = data || { course: {} };

	return { course, isPending, isSuccess, isError, error };
}
