import style from "./Container.module.css";

function Container({ children, size, className }) {
	return (
		<div className={`${style.funtenjer}  ${style[size]} ${className}`}>
			{children}
		</div>
	);
}

export default Container;
