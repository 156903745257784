import { OTPInput } from "input-otp";
import { useController, useFormContext } from "react-hook-form";
import InputError from "./InputError";

export default function FormOTP({ name }) {
	const {
		control,
		setValue,
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	const {
		field,
		fieldState: { error },
	} = useController({ name, control });

	return (
		<div>
			<OTPInput
				maxLength={6}
				id={name}
				name={name}
				containerClassName="group flex items-center has-[:disabled]:opacity-30"
				value={field.value}
				onChange={field.onChange}
				render={({ slots }) => (
					<>
						<div className="flex">
							{slots.slice(0, 3).map((slot, idx) => (
								<Slot key={idx} {...slot} />
							))}
						</div>

						<FakeDash />

						<div className="flex">
							{slots.slice(3).map((slot, idx) => (
								<Slot key={idx} {...slot} />
							))}
						</div>
					</>
				)}
			/>
			{error && <InputError>{error.message}</InputError>}
		</div>
	);
}

function Slot(props) {
	return (
		<div
			className={cn(
				"relative",

				/* Width and Height */
				"w-12 h-14", // Base size for small devices
				"sm:w-12 sm:h-14", // Small devices (≥640px)
				"md:w-16 md:h-18", // Medium devices (≥768px

				/* Text Size */
				"text-lg", // Base text size
				"sm:text-2xl", // Small devices
				"md:text-3xl", // Medium devices

				/* Flex and Alignment */
				"flex items-center justify-center",

				/* Transitions */
				"transition-all duration-300",

				/* Borders and Rounded Corners */
				"border border-y border-r",
				"first:border-l",
				"first:rounded-l-md",
				"last:rounded-r-md",

				/* Hover and Focus States */
				"group-hover:border-accent-foreground/20",
				"group-focus-within:border-accent-foreground/20",

				/* Outline */
				"outline outline-0 outline-accent-foreground/20",
				props.isActive && "outline-4 outline-accent-foreground"
			)}
		>
			{props.char !== null && (
				<div className="flex items-center justify-center w-full h-full">
					{props.char}
				</div>
			)}
			{props.hasFakeCaret && <FakeCaret />}
		</div>
	);
}
// You can emulate a fake textbox caret!
function FakeCaret() {
	return (
		<div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
			<div className="w-px h-8 bg-white" />
		</div>
	);
}

// Inspired by Stripe's MFA input.
function FakeDash() {
	return (
		<div className="flex w-10 justify-center items-center">
			<div className="w-3 h-1 rounded-full bg-border" />
		</div>
	);
}

// Small utility to merge class names.
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
	return twMerge(clsx(inputs));
}
