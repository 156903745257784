import { useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import InputError from "../ui/InputError";
import { Controller } from "react-hook-form";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectItem,
	SelectContent,
} from "../ui/Select";

export default function FormSelect({
	name,
	choices = [],
	label,
	placeholder = "",
	required = false,
}) {
	const {
		register,
		control,
		formState: { errors, isSubmitting },
	} = useFormContext();

	if (!label) label = capitalize(name);
	if (!placeholder) placeholder = "Select a value";

	return (
		<div>
			<Label htmlFor={name}>{label}</Label>
			<Controller
				control={control}
				name={name}
				render={({ field }) => {
					return (
						<Select
							name={field.name}
							onValueChange={field.onChange}
							defaultValue={field.value}
							required={required}
						>
							<SelectTrigger className="mt-1">
								<SelectValue placeholder={placeholder} />
							</SelectTrigger>
							<SelectContent>
								{choices.map((choice) => (
									<SelectItem
										key={choice.value}
										value={choice.value}
									>
										{choice.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					);
				}}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}
