import React from "react";
import style from "./ReplyButton.module.css";
import Icon from "@/ui/icon/Icon";

function ReplyButton({ setIsReplying, isReplying, commentId, replyingTo }) {
	return (
		<button
			onClick={() =>
				setIsReplying(replyingTo === commentId ? null : commentId)
			}
			className={`${style.replyBtn} ${isReplying ? style.active : ""}`}
		>
			<div className={style.icoHolder}>
				<Icon icon="fa-arrow-turn-down"></Icon>
			</div>
			Odgovori
		</button>
	);
}

export default ReplyButton;
