import { useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import InputError from "../ui/InputError";
import { Controller } from "react-hook-form";
import { Calendar } from "../ui/Calendar";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/Popover";
import { Button } from "../ui/Button";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { cn } from "../../lib/utils";

export default function FormDatePicker({
	name,
	type = "text",
	label,
	placeholder = "",
	required = false,
}) {
	const {
		register,
		control,
		formState: { errors, isSubmitting },
	} = useFormContext();

	if (!label) label = name;
	if (!placeholder) placeholder = "Select a value";

	return (
		<div>
			<Label htmlFor={name}>{label}</Label>
			<Controller
				control={control}
				name={name}
				render={({ field }) => {
					return (
						<Popover>
							<PopoverTrigger asChild>
								<Button
									variant={"outline"}
									className={cn(
										"w-full min-w-[240px] pl-3 text-left font-normal mt-1",
										!field.value && "text-muted-foreground"
									)}
								>
									{field.value ? (
										format(field.value, "PPP")
									) : (
										<span>Pick a date</span>
									)}
									<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
								</Button>
							</PopoverTrigger>
							<PopoverContent
								className="w-auto p-0"
								align="start"
							>
								<Calendar
									mode="single"
									selected={field.value}
									onSelect={field.onChange}
									disabled={(date) =>
										date < new Date("2020-01-01")
									}
									initialFocus
								/>
							</PopoverContent>
						</Popover>
					);
				}}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}
