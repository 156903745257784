import { useGetCourse } from "@/features/courses/hooks/useGetCourse";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import { errorToast } from "@/ui/toast/Toast";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export function Course() {
	const { courseSlug } = useParams();
	const { data, isPending, isSuccess, isError, error } = useGetCourse({
		courseSlug,
	});
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	useEffect(() => {
		if (isError) {
			errorToast(
				"Desila se greška pri učitavanju kursa. Molimo pokušajte opet."
			);
			navigate("/");
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			const unitDetails = data.video || data.quiz;

			const currentCourseSlug = unitDetails.module?.course?.slug;
			const moduleSlug = unitDetails.module?.slug;
			const unitSlug = unitDetails.slug;

			queryClient.setQueryData(
				[
					"courses",
					currentCourseSlug,
					"modules",
					moduleSlug,
					"units",
					unitSlug,
				],
				data
			);
			navigate(
				`/courses/${currentCourseSlug}/${moduleSlug}/${unitSlug}`,
				{
					replace: true,
				}
			);
		}
	}, [isSuccess]);

	return <FullPageLoader />;
}
