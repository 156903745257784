import { useFormContext } from "react-hook-form";
import { Label } from "../ui/Label";
import { Textarea } from "../ui/Textarea";
import InputError from "../ui/InputError";
import { Controller } from "react-hook-form";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default function FormHTML({ name, label, ...props }) {
	const {
		control,
		register,
		formState: { errors, isSubmitting },
	} = useFormContext();

	if (!label) label = capitalize(name);

	return (
		<div>
			<Label htmlFor={name}>{label}</Label>

			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<div className="mt-1.5">
						<SunEditor
							name={field.name}
							onChange={field.onChange}
							setContents={field.value}
							setOptions={{
								minHeight: "160px",
								buttonList: [
									["formatBlock"],
									["bold", "underline", "italic", "strike"],
									["undo", "redo"],
									["removeFormat"],
								],
								defaultValue: field.value,
							}}
						/>
					</div>
				)}
			/>

			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	);
}

function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}
