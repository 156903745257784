import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminDashboard from "./pages/Dashboard";
import AdminLayout from "./AdminLayout";
import Dashboard from "./pages/Dashboard";
import { Navigate } from "react-router-dom";
import Courses from "./pages/courses/ViewCourses";
import Users from "./pages/users/ViewUsers";
import ViewUser from "./pages/users/ViewUser";
import EditUser from "./pages/users/EditUser";
import ViewCourse from "./pages/courses/ViewCourse";
import EditCourse from "./pages/courses/EditCourse";
import ViewModule from "./pages/courses/ViewModule";
import EditModule from "./pages/courses/EditModule";
import EditUnit from "./pages/courses/EditUnit";
import ViewUnit from "./pages/courses/ViewUnit";

function AdminRouter() {
	return (
		<Routes>
			<Route path="/" element={<AdminLayout />}>
				<Route
					index
					element={<Navigate replace to="/admin/courses/" />}
				/>
				<Route path="dashboard" element={<Dashboard />} />
				<Route path="users">
					<Route index element={<Users />} />
					<Route path=":userUUID">
						<Route index element={<ViewUser />} />
						<Route path="edit" element={<EditUser />} />
					</Route>
				</Route>
				<Route path="courses">
					<Route index element={<Courses />} />
					<Route path=":courseID">
						<Route
							index
							element={<Navigate replace to="details/" />}
						/>
						<Route path="modules">
							<Route
								index
								element={<ViewCourse tab="modules" />}
							/>
							<Route path=":moduleID">
								<Route
									index
									element={<Navigate replace to="units/" />}
								/>
								<Route
									path="details"
									element={<ViewModule tab="details" />}
								/>
								<Route path="units">
									<Route
										index
										element={<ViewModule tab="units" />}
									/>
									<Route path=":unitID">
										<Route
											index
											element={
												<Navigate
													replace
													to="details/"
												/>
											}
										/>
										<Route
											path="details"
											element={<ViewUnit tab="details" />}
										/>
										<Route
											path="video-details"
											element={
												<ViewUnit tab="video-details" />
											}
										/>
										<Route
											path="quiz-questions"
											element={
												<ViewUnit tab="quiz-questions" />
											}
										/>

										<Route
											path="edit"
											element={<EditUnit />}
										/>
									</Route>
								</Route>
								<Route path="edit" element={<EditModule />} />
							</Route>
						</Route>
						<Route
							path="overview"
							element={<ViewCourse tab="overview" />}
						/>
						<Route
							path="details"
							element={<ViewCourse tab="details" />}
						/>
						<Route path="edit" element={<EditCourse />} />
					</Route>
				</Route>
			</Route>
		</Routes>
	);
}

export default AdminRouter;
