import { sendVerificationCode } from "../api";
import { useApiSend } from "@/hooks/useApi";

export function useSendVerificationCode() {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		fn: (data) => sendVerificationCode({ email: data.email }),
	});

	return { sendCode: mutateAsync, isPending, error, isError };
}
