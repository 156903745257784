import React, { useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import style from "./CoursesTagContainer.module.css";

import Icon from "@/ui/icon/Icon";

function CoursesTagContainer({
	categories,
	selectedSubcategory,
	setSelectedSubcategory,
}) {
	const availableSubcategories = useMemo(() => {
		let res = [];
		for (const category of categories)
			for (const subcategory of category.subcategories)
				if (subcategory.semifilteredCourseCount > 0)
					res.push(subcategory);
		return res;
	}, [categories]);

	useEffect(() => {
		if (!selectedSubcategory) return;

		// Unselect the selected subcategory if it's no longer available
		if (
			!availableSubcategories.some(
				(category) => category.slug == selectedSubcategory
			)
		)
			setSelectedSubcategory("");
	}, [availableSubcategories]);

	const handleTagClick = (tagSlug) => {
		setSelectedSubcategory((prevSelectedSubcategory) =>
			prevSelectedSubcategory === tagSlug ? "" : tagSlug
		);
	};

	if (availableSubcategories.length == 0) return <></>;

	return (
		<Swiper
			direction="horizontal"
			slidesPerView="auto"
			slidesPerGroup={6}
			pagination={{ clickable: true }}
			scrollbar={{
				draggable: true,
			}}
			keyboard={{
				enabled: true,
				onlyInViewport: true,
			}}
			navigation={{
				nextEl: `.custom-next`,
				prevEl: `.custom-prev`,
			}}
			mousewheel={true}
			modules={[Keyboard, Navigation]}
			className={style.tagsContainer}
		>
			{availableSubcategories.map((category) => (
				<SwiperSlide key={category.slug} className={style.tagHolder}>
					<div
						className={`${style.tag} ${
							selectedSubcategory === category.slug
								? style.activeTag
								: ""
						}`}
						onClick={() => handleTagClick(category.slug)}
					>
						<span>{category.name}</span>

						<span className={style.unselect}>
							<Icon icon="fa-times" />
						</span>
					</div>
				</SwiperSlide>
			))}
			<div className={`custom-next ${style.nextButton}`}>
				<Icon icon="fa-chevron-right" />
			</div>
			<div className={`custom-prev ${style.prevButton}`}>
				<Icon icon="fa-chevron-left" />
			</div>
		</Swiper>
	);
}

export default CoursesTagContainer;
