import React, { useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import FormTextArea from "@/features/forms/components/FormTextArea";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { usePostUnitComment } from "../../hooks/usePostUnitComment";
import EmojiPicker from "emoji-picker-react";
import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";
import style from "./ReplyForm.module.css";

function ReplyForm({ comment, unit, setIsReplying }) {
	const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
	const emojiPickerRef = useRef(null);

	const formMethods = useFormWithValidation();
	const {
		handleSubmit,
		setError,
		setValue,
		formState: { error, isSubmitting },
	} = formMethods;

	const { save } = usePostUnitComment({ unit });

	const onSubmit = async (data) => {
		if (isSubmitting) return;

		await save({
			content: data.content,
			reply_to: comment.uuid,
		});
		setIsReplying(false);
		setValue("content", "");
	};

	useEffect(() => {
		if (formMethods && formMethods.setFocus) {
			formMethods.setFocus("content");
		}
	}, [formMethods]);

	const onEmojiClick = (emojiData, event) => {
		if (!emojiData || !emojiData.emoji) {
			console.error(
				"Emoji data is undefined. Check the onEmojiClick parameters."
			);
			return;
		}
		const currentContent = formMethods.getValues("content") || "";
		formMethods.setValue("content", `${currentContent}${emojiData.emoji}`);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				emojiPickerRef.current &&
				!emojiPickerRef.current.contains(event.target) &&
				!event.target.closest(`.${style.emojiToggleBtn}`)
			) {
				setIsEmojiPickerVisible(false);
			}
		};

		if (isEmojiPickerVisible) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isEmojiPickerVisible]);

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			handleSubmit(onSubmit)();
		}
	};

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)} className={style.replyForm}>
				<FormTextArea
					name="content"
					required
					placeholder="Unesite odgovor"
					className={style.input}
					onKeyDown={handleKeyDown}
				/>

				<div className={style.footer}>
					<div className={style.emojiHolder}>
						<button
							type="button"
							onClick={() =>
								setIsEmojiPickerVisible((prev) => !prev)
							}
							className={`${style.emojiToggleBtn} ${
								isEmojiPickerVisible ? style.active : ""
							}`}
							aria-label="Toggle Emoji Picker"
						>
							<Icon icon="fa-smile"></Icon>
						</button>
					</div>
					<div className={style.btnsHolder}>
						<Button
							onClick={() => setIsReplying(false)}
							className={style.cancelBtn}
						>
							<Icon icon="fa-times"></Icon>
						</Button>

						<Button
							className={style.sendBtn}
							disabled={isSubmitting}
							type="accent"
						>
							{isSubmitting ? (
								"..."
							) : (
								<span>
									<Icon icon="fa-reply"></Icon>
								</span>
							)}
						</Button>
					</div>

					{isEmojiPickerVisible && (
						<div
							className={style.emojiPickerContainer}
							ref={emojiPickerRef}
						>
							<EmojiPicker
								reactionsDefaultOpen={true}
								onEmojiClick={onEmojiClick}
							/>
						</div>
					)}
				</div>
			</form>
		</FormProvider>
	);
}

export default ReplyForm;
