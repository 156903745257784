import style from "./IncompleteCourseCard.module.css";
import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";
import { Link } from "react-router-dom";

import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";

import { useGetIncompleteCourse } from "@/features/courses/hooks/useGetIncompleteCourse";

function IncompleteCourseCard({ continueWatching }) {
	const { data, isError, error, isSuccess } = useGetIncompleteCourse();

	if (isError) return <>Error: {JSON.stringify(error)} </>;

	if (!isSuccess) return <FullPageLoader />;

	const courseProgress = data.courseProgress;

	const total_units =
		courseProgress.total_videos + courseProgress.total_quizes;
	const units_completed =
		courseProgress.videos_completed + courseProgress.quizes_completed;

	const units_percent = Math.round((100 * units_completed) / total_units);

	return (
		<section className={style.continue}>
			<div className={style.header}>
				<h2>Dovršite započeto</h2>
			</div>
			<div className={style.body}>
				<div className={style.continueCourse}>
					<Link
						to={`/courses/${data.video.module.course.slug}/${data.video.module.slug}/${data.video.slug}/`}
						className={style.thumbnailHolder}
					>
						<div
							className={style.thumbnail}
							style={{
								"--image": `url(${data?.video?.module?.course?.thumbnail})`,
							}}
						></div>
					</Link>
					<div className={style.info}>
						<h3>{data?.video?.module?.course?.name}</h3>
						<div className={style.labels}>
							<div className={style.label}>
								<Icon icon="fa-clock" />
								<span>Posljednji put posjetili prije</span>
								<span style={{ fontWeight: "600" }}>
									manje od 1 dan
								</span>
							</div>
						</div>
						<div className={style.progressHolder}>
							<div className={style.progress}>
								<div
									className={style.progressBar}
									style={{
										"--progress": `${units_percent}%`,
									}}
								>
									<div className={style.circle}></div>
								</div>
							</div>
							<div className={style.progressStatus}>
								<div>
									{units_completed} / {total_units}
								</div>
								<div>{units_percent}%</div>
							</div>
						</div>
						<div className={style.footer}>
							<div className={style.currently}>
								<div className={style.icoHolder}>
									<Icon icon="fa-play" />
								</div>
								<div className={style.subHeader}>
									<span>Trenutno gledate</span>
									<span>{data?.video?.name}</span>
								</div>
							</div>
							<Button
								to={`/courses/${data.video.module.course.slug}/${data.video.module.slug}/${data.video.slug}/`}
								type="accent"
							>
								<span>Nastavi {""}</span>
								<Icon icon="fa-arrow-right" />
							</Button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default IncompleteCourseCard;
