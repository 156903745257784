import style from "./ChangePasswordForm.module.css";

import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { FormProvider } from "react-hook-form";
import { successToast } from "@/ui/toast/Toast";
import Button from "@/ui/button/Button";

import Icon from "@/ui/icon/Icon";

import SettingSection from "../settingsSection/SettingsSection";

import InputPassword from "@/features/forms/components/InputPassword";

export default function ChangePasswordForm({ user }) {
	const formMethods = useFormWithValidation();

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods;

	const onSubmit = async (data) => {
		successToast("Uspjesno spasena nova lozinka");
	};

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SettingSection
					title="Promijeni lozinku"
					description="Uredite svoju lozinku kako vi zelite"
				>
					<InputPassword
						name="password"
						placeholder="Unesi lozinku"
						label="Nova Lozinka"
						required={true}
					/>
					<InputPassword
						name="repeat_password"
						placeholder="Unesi lozinku"
						label="Ponovi lozinku"
						required={true}
					/>
				</SettingSection>

				<div className={style.btnHolder}>
					<Button
						className={style.btn}
						type="accent"
						disabled={isSubmitting}
					>
						<Icon icon="fa-floppy-disk" />
						{isSubmitting ? <Icon icon="fa-ellipsis" /> : "Sačuvaj"}
					</Button>
				</div>
			</form>
		</FormProvider>
	);
}
