import {
	GripIcon,
	ChevronRightIcon,
	EditIcon,
	TrashIcon,
	FilmIcon,
	NotepadTextIcon,
} from "lucide-react";
import ReorderableList from "../ReorderableList";
import { Button } from "../ui/Button";
import { useEffect, useMemo, useState } from "react";
import { compareArraysByID } from "../../lib/utils";
import Loader from "../Loader";
import CreateModuleModal from "./CreateModuleModal";
import { useGetUnits } from "../../hooks/useGetUnits";
import { useReorderModules } from "../../hooks/useReorderModules";
import { successToast } from "@/ui/toast/Toast";
import CreateVideoUnitModal from "./CreateVideoUnitModal";
import CreateQuizUnitModal from "./CreateQuizUnitModal";
import { useReorderUnits } from "../../hooks/useReorderUnits";

export default function ModuleUnits({ courseID, module }) {
	const { units, isSuccess, isPending, isError, error } = useGetUnits({
		courseID: courseID,
		moduleID: module.id,
	});

	const [orderedUnits, setOrderedUnits] = useState([]);

	useEffect(() => {
		setOrderedUnits(
			units.map((unit) => {
				return {
					...unit,
					name: (
						<span className="flex items-center gap-2">
							{unit.name}
							{unit.unit_type == "video" ? (
								<FilmIcon className="text-gray-600 h-5 w-5" />
							) : (
								<NotepadTextIcon className="text-gray-600 h-5 w-5" />
							)}
						</span>
					),
				};
			})
		);
	}, [units]);

	const isDirty = useMemo(
		() => !compareArraysByID(units, orderedUnits),
		[units, orderedUnits]
	);

	const reorderUnitsMutation = useReorderUnits({
		courseID: courseID,
		moduleID: module.id,
	});

	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
	const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);

	async function handleSaveClick() {
		await reorderUnitsMutation.save(
			orderedUnits.map((module) => module.id)
		);
		successToast("Uspjesno spašen novi poredak module");
	}

	return (
		<div>
			<h2 className="mb-1 font-semibold">Units</h2>
			{isSuccess && (
				<ReorderableList
					items={orderedUnits}
					setItems={setOrderedUnits}
					itemBaseURL={`/admin/courses/${courseID}/modules/${module.id}/units/`}
				/>
			)}
			{isPending && <Loader />}
			<div className="flex gap-2">
				<Button
					variant="outline"
					onClick={() => setIsVideoModalOpen(true)}
					className="mt-3"
				>
					Add Video
				</Button>
				<Button
					variant="outline"
					onClick={() => setIsQuizModalOpen(true)}
					className="mt-3"
				>
					Add Quiz
				</Button>
				{isDirty && (
					<Button
						variant="default"
						className="mt-3 ml-auto"
						onClick={handleSaveClick}
					>
						{reorderUnitsMutation.isPending ? "Saving" : "Save"}
					</Button>
				)}
			</div>
			<CreateVideoUnitModal
				courseID={courseID}
				module={module}
				isOpen={isVideoModalOpen}
				onOpenChange={setIsVideoModalOpen}
			/>
			<CreateQuizUnitModal
				courseID={courseID}
				module={module}
				isOpen={isQuizModalOpen}
				onOpenChange={setIsQuizModalOpen}
			/>
		</div>
	);
}
