import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "@/features/auth/hooks/useUser";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";

function ProtectedRoute({ children }) {
	const { user, isLoading } = useUser();
	if (isLoading) return <FullPageLoader />;
	return user?.role === "admin" ? children : <Navigate to="/" />;
}

export default ProtectedRoute;
