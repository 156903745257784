import style from "./ChoosePlan.module.css";

import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";

import { Link, useNavigate } from "react-router-dom";
import { successToast } from "@/ui/toast/Toast";
import { useSubscribe } from "@/features/payments/hooks/useSubscribe";
import Spinner from "@/features/forms/components/Spinner";

function ChoosePlan({ nextStep, prevStep }) {
	const navigate = useNavigate();
	const { subscribe, isPending } = useSubscribe();

	const plans = [
		{
			name: "Besplatno",
			price: 0,
			benefits: [
				{
					name: "Pristup besplatnom sadržaju",
					check: true,
				},
				{
					name: "Crtifikat po završetku kursa",
					check: false,
				},
				{
					name: "Ai asistencija do 5000 kredita",
					check: false,
				},
				{
					name: "Offline pristup",
					check: false,
				},
			],
			currency: "KM",
			isLoading: false,
			preferred: false,
			onSelect: async () => {
				successToast("Uspješno ste završili prijavu!");
				navigate("/");
			},
		},
		{
			name: "Premium plan",
			price: "49,99",
			benefits: [
				{
					name: "Neograničen pristup sadržaju",
					check: true,
				},
				{
					name: "Crtifikat po završetku kursa",
					check: true,
				},
				{
					name: "Ai asistencija do 5000 kredita",
					check: true,
				},
				{
					name: "Offline pristup",
					check: true,
				},
			],
			generationCredits: "Unlimited",
			currency: "KM",
			isLoading: isPending,
			onSelect: async () => {
				await subscribe();
			},
			preferred: true,
		},
	];

	return (
		<div className={style.content}>
			<div className={style.header}>
				<Icon icon="fa-tasks" />
				<h2>Pretplatite se</h2>
			</div>
			<div className={style.body}>
				<div className={style.offersHolder}>
					{plans.map((plan) => (
						<div
							className={`${style.offer} ${
								plan.preferred ? style.preferred : ""
							}`}
							key={plan.name}
						>
							<div className={style.offerHeader}>
								<h3>{plan.name}</h3>
							</div>
							<div className={style.offerBody}>
								<ul>
									{plan.benefits.map((benefit, index) => (
										<li key={index}>
											<Icon
												icon={
													benefit.check
														? "fa-check"
														: "fa-times"
												}
											/>
											<span>{benefit.name}</span>
										</li>
									))}
								</ul>
							</div>
							<div className={style.offerFooter}>
								<div className={style.info}>
									<div className={style.price}>
										<div className={style.amount}>
											{plan.price}
										</div>
										<div className={style.currency}>
											{plan.currency}
										</div>
									</div>
									<div className={style.sepp}>
										<Icon icon="fa-slash-forward" />
									</div>
									<div className={style.period}>mjesečno</div>
								</div>
								<Button
									onClick={plan.onSelect}
									className={style.btn}
								>
									{plan.isLoading ? <Spinner /> : "Nastavi"}
								</Button>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default ChoosePlan;
